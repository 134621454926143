import axios from 'axios'
import { uniqueId } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CssBaseline from '@mui/material/CssBaseline'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import PaidIcon from '@mui/icons-material/Paid'

import { varLocalStorage } from '../../core/configs'
import api from '../../services/apiMotorizada'

import * as Components from '../../components'
import * as Functions from '../../utils/functions'
import * as Mask from '../../utils/mask'
import * as Validators from '../../utils/validators'

import * as Data from './data'
import * as styles from './styles'
import { links } from '../../routes/data'

const PainelMotorizada = () => {
  const [method, setMethod] = React.useState('table')
  const [uuid, setUuid] = React.useState('')
  const [member1uuid, setMember1uuid] = React.useState(uniqueId())
  const [member2uuid, setMember2uuid] = React.useState(uniqueId())
  const [member3uuid, setMember3uuid] = React.useState(uniqueId())
  const [member4uuid, setMember4uuid] = React.useState(uniqueId())
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [isCaptain, setIsCaptain] = React.useState(null)
  const [isBoatPilot, setIsBoatPilot] = React.useState(null)
  const [config, setConfig] = React.useState({})
  const [token, setToken] = React.useState('')

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [checkMember1, setCheckMember1] = React.useState(false)
  const [checkMember2, setCheckMember2] = React.useState(false)
  const [checkMember3, setCheckMember3] = React.useState(false)
  const [checkMember4, setCheckMember4] = React.useState(false)
  const [checkEditMember1, setEditCheckMember1] = React.useState(false)
  const [checkEditMember2, setEditCheckMember2] = React.useState(false)
  const [checkEditMember3, setEditCheckMember3] = React.useState(false)
  const [checkEditMember4, setEditCheckMember4] = React.useState(false)

  const [formData, setFormData] = React.useState({})
  const [paymentData, setPaymentData] = React.useState([])
  const [member1, setMember1] = React.useState({})
  const [member2, setMember2] = React.useState({})
  const [member3, setMember3] = React.useState({})
  const [member4, setMember4] = React.useState({})
  const [rows, setRows] = React.useState([])

  const [selectedRow, setSelectedRow] = React.useState(null)
  const [editFormData, setEditFormData] = React.useState({})
  const [editMember1, setEditMember1] = React.useState({})
  const [editMember2, setEditMember2] = React.useState({})
  const [editMember3, setEditMember3] = React.useState({})
  const [editMember4, setEditMember4] = React.useState({})

  const [typePayment, setTypePayment] = React.useState('Pix')
  const [photo, setPhoto] = React.useState(null)
  const [paymentId, setPaymentId] = React.useState('')
  const [paymentDate, setPaymentDate] = React.useState('')
  const [newPayment, setNewPayment] = React.useState(true)
  const [countPayment, setCountPayment] = React.useState(0)

  const theme = useTheme()
  const navigate = useNavigate()

  const handleChangeForm = (name, newValue) => {
    Functions.changeForm(formData, setFormData, name, newValue)
  }

  const TeamMemberChangeForm1 = (name, newValue) => {
    Functions.changeForm(member1, setMember1, name, newValue)
  }

  const TeamMemberChangeForm2 = (name, newValue) => {
    Functions.changeForm(member2, setMember2, name, newValue)
  }

  const TeamMemberChangeForm3 = (name, newValue) => {
    Functions.changeForm(member3, setMember3, name, newValue)
  }

  const TeamMemberChangeForm4 = (name, newValue) => {
    Functions.changeForm(member4, setMember4, name, newValue)
  }

  const configToken = () => {
    const authData = JSON.parse(localStorage.getItem(varLocalStorage))
    if (authData) {
      setToken(authData.token)
      setConfig({
        headers: {
          Autentication: authData.token
        }
      })
    } else {
      setConfig({})
      navigate(links.login)
    }
  }

  const refreshValue = type => {
    if (type === 1) {
      setCheckMember1(true)
      setMember1({
        ...member1,
        is_captain: false,
        is_boat_pilot: false
      })
    } else if (type === 2) {
      setCheckMember2(true)
      setMember2({
        ...member2,
        is_captain: false,
        is_boat_pilot: false
      })
    }
    if (type === 3) {
      setCheckMember3(true)
      setMember3({
        ...member3,
        is_captain: false,
        is_boat_pilot: false
      })
    } else if (type === 4) {
      setCheckMember4(true)
      setMember4({
        ...member4,
        is_captain: false,
        is_boat_pilot: false
      })
    }
  }

  const deleteData = async () => {
    setMethod('table')
    setTypeAlert('loading')

    try {
      await api.delete(`/team/${uuid}`, config)

      setMsg('Cadastro Deletado com Sucesso')
      setTypeAlert('success')
    } catch (err) {
      if (err?.response?.data?.detail[0]?.msg) {
        setMsg(err.response.data.detail[0].msg)
      } else {
        setMsg('Não foi possível concluir a Operação!')
      }
      setTypeAlert('error')
    }
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    let aux = []
    try {
      const resp = await api.get('/team/', { ...config, params: { all: true } })
      aux = resp.data
      const temp = resp.data.filter(
        elemento => elemento.status_payment === true
      ).length
      setCountPayment(temp)
      setOpenAlert(false)
    } catch (err) {
      if (err?.message && err.message === 'Network Error') {
        setMsg(
          'Erro ao carregar os dados! Verifique a internet e tente novamente!'
        )
      }
      setTypeAlert('error')
    }

    const temp = []
    for (let i = 0; i < aux.length; i++) {
      temp.push({
        ...aux[i],
        member1: aux[i].member[0].name,
        member1_Cpf: aux[i].member[0].cpf,
        member2: aux[i].member[1].name,
        member2_Cpf: aux[i].member[1].cpf,
        member3: aux[i].member[2]?.name || '---------------',
        member3_Cpf: aux[i].member[2]?.cpf || '',
        member4: aux[i].member[3]?.name || '---------------',
        member4_Cpf: aux[i].member[3]?.cpf || ''
      })
    }

    setRows(temp)
  }

  const countMembers = () => {
    let value = 0
    if (checkMember1 === true) {
      value = value + 1
    }
    if (checkMember2 === true) {
      value = value + 1
    }
    if (checkMember3 === true) {
      value = value + 1
    }
    if (checkMember4 === true) {
      value = value + 1
    }

    return value
  }

  const handleSubmit = async () => {
    setMsg('')
    setIsSubmit(true)
    if (method === 'edit') {
      if (countMembers() >= 2) {
        let sendError = false
        const validate = Functions.validateForm(Data.editTeam, formData)
        let validateM1 = false
        if (checkMember1 === true) {
          validateM1 = Functions.validateForm(Data.editMember, member1)
        }
        let validateM2 = false
        if (checkMember2 === true) {
          validateM2 = Functions.validateForm(Data.editMember, member2)
        }
        let validateM3 = false
        if (checkMember3 === true) {
          validateM3 = Functions.validateForm(Data.editMember, member3)
        }
        let validateM4 = false
        if (checkMember4 === true) {
          validateM4 = Functions.validateForm(Data.editMember, member4)
        }

        if (
          validate === false &&
          validateM1 === false &&
          validateM2 === false &&
          validateM3 === false &&
          validateM4 === false
        ) {
          setTypeAlert('loading')
          setOpenAlert(true)

          if (checkEditMember1 === true && checkMember1 === false) {
            try {
              await api.delete(`/member/${member1uuid}`, config)
            } catch (err) {
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          if (checkEditMember2 === true && checkMember2 === false) {
            try {
              await api.delete(`/member/${member2uuid}`, config)
            } catch (err) {
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          if (checkEditMember3 === true && checkMember3 === false) {
            try {
              await api.delete(`/member/${member3uuid}`, config)
            } catch (err) {
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          if (checkEditMember4 === true && checkMember4 === false) {
            try {
              await api.delete(`/member/${member4uuid}`, config)
            } catch (err) {
              console.log(err)
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          if (checkEditMember3 === false && checkMember3 === true) {
            const send = Functions.formData(member3)
            send.append('team_uuid', selectedRow.uuid)
            try {
              await api.post('/member/', send, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Autentication: token
                }
              })
            } catch (err) {
              console.log(err)
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          if (checkEditMember4 === false && checkMember4 === true) {
            const send = Functions.formData(member4, selectedRow.uuid)
            send.append('team_uuid', selectedRow.uuid)
            try {
              await api.post('/member/', send, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Autentication: token
                }
              })
            } catch (err) {
              console.log(err)
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          const tempTime = Functions.sendData(editFormData, formData)
          const tam = Object.keys(tempTime).length
          if (tam > 0) {
            try {
              await api.put(`/team/${uuid}`, tempTime, config)
            } catch (err) {
              console.log(err)
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          const tempMember1 = Functions.sendData(editMember1, member1)
          const tam1 = Object.keys(tempMember1).length

          if (tam1 > 0) {
            const sendData = Functions.formData(tempMember1)

            try {
              await api.put(`/member/${member1uuid}`, sendData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Autentication: token
                }
              })
            } catch (err) {
              console.log(err)
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          const tempMember2 = Functions.sendData(editMember2, member2)
          const tam2 = Object.keys(tempMember2).length

          if (tam2 > 0) {
            const sendData = Functions.formData(tempMember2)

            try {
              await api.put(`/member/${member2uuid}`, sendData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Autentication: token
                }
              })
            } catch (err) {
              console.log(err)
              sendError = true
              if (err?.response?.data?.detail[0]?.msg) {
                setMsg(err.response.data.detail[0].msg)
              } else {
                setMsg('Não foi possível concluir a Operação!')
              }
            }
          }

          if (checkEditMember3 === true && checkMember3 === true) {
            const tempMember3 = Functions.sendData(editMember3, member3)
            const tam3 = Object.keys(tempMember3).length

            if (tam3 > 0) {
              const sendData = Functions.formData(tempMember3)

              try {
                await api.put(`/member/${member3uuid}`, sendData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Autentication: token
                  }
                })
              } catch (err) {
                console.log(err)
                sendError = true
                if (err?.response?.data?.detail[0]?.msg) {
                  setMsg(err.response.data.detail[0].msg)
                } else {
                  setMsg('Não foi possível concluir a Operação!')
                }
              }
            }
          }

          if (checkEditMember4 === true && checkMember4 === true) {
            const tempMember4 = Functions.sendData(editMember4, member4)
            const tam4 = Object.keys(tempMember4).length

            if (tam4 > 0) {
              const sendData = Functions.formData(tempMember4)

              try {
                await api.put(`/member/${member4uuid}`, sendData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Autentication: token
                  }
                })
              } catch (err) {
                console.log(err)
                sendError = true
                if (err?.response?.data?.detail[0]?.msg) {
                  setMsg(err.response.data.detail[0].msg)
                } else {
                  setMsg('Não foi possível concluir a Operação!')
                }
              }
            }
          }

          if (sendError === false) {
            setMsg('Cadastro Atualizdo com Sucesso!')
            setTypeAlert('success')
          } else {
            setTypeAlert('error')
          }
        }
      } else {
        setMsg('O time precisa ter pelo menos 2 membros!')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    } else if (method === 'payment') {
      if (newPayment === true) {
        const validate1 = typeof photo === 'string'
        const validate2 = Validators.Required(paymentId).err
        const validate3 = Validators.ValidatorDate(paymentDate).err

        if (
          validate1 === false &&
          validate2 === false &&
          validate3 === false &&
          photo
        ) {
          let sendError = false
          let resp = null
          setTypeAlert('loading')
          setOpenAlert(true)

          const sendPhoto = new FormData()
          sendPhoto.append('file', photo)
          sendPhoto.append('type', 'image')

          try {
            resp = await api.post('/file/', sendPhoto, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Autentication: token
              }
            })

            const sendData = {
              status_payment: true,
              payment_type: typePayment,
              payment_id: paymentId,
              payment_date: Functions.sendDate(paymentDate),
              file_payment_voucher: resp.data
            }

            if (sendError === false) {
              try {
                await api.put(`/team/${uuid}`, sendData, config)
              } catch (err) {
                console.log(err)
                sendError = true
              }
            }
          } catch (err) {
            console.log(err)
            sendError = true
          }

          if (sendError === false) {
            setMsg('Comprovante Enviado com Sucesso!')
            setTypeAlert('success')
          } else {
            setMsg('Não foi possível concluir a Operação!')
            setTypeAlert('error')
          }
        }
      } else {
        setTypeAlert('loading')
        setOpenAlert(true)

        const sendData = {
          status_payment: false,
          payment_type: 'Null',
          payment_id: 'Null',
          file_payment_voucher: 'Null'
        }

        try {
          await api.put(`/team/${uuid}`, sendData, config)

          setMsg('Pagamento Deletado com Sucesso')
          setTypeAlert('success')
        } catch (err) {
          console.log(err)
          setMsg('Não foi possível concluir a Operação!')
          setTypeAlert('error')
        }
      }
    }
  }

  const loadAdress = async cep => {
    try {
      const resp = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (resp.data?.cep) {
        setFormData({
          ...formData,
          state: resp.data.uf,
          city: resp.data.localidade,
          district: resp.data.bairro,
          street: resp.data.logradouro,
          complement: resp.data.complemento
        })
      }
    } catch (err) {}
  }

  React.useEffect(() => {
    if (
      formData?.zip_code &&
      formData.zip_code.replace(/[^0-9]/g, '').length === 8
    ) {
      loadAdress(formData.zip_code.replace(/[^0-9]/g, ''))
    }
  }, [formData && formData?.zip_code])

  React.useEffect(() => {
    if (method === 'table') {
      setIsSubmit(false)
      setSelectedRow(null)
      setIsCaptain(null)
      setIsBoatPilot(null)
      setFormData([])
      setMember1({})
      setMember2({})
      setMember3({})
      setMember4({})
      setNewPayment(true)
      setPhoto(null)
      setTypePayment('Pix')
      setPaymentDate('')
      setPaymentId('')
    } else if (method === 'delete') {
      setMsg('Essa operação é Irreversível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    } else if (method === 'edit') {
      setFormData(Functions.filterData(Data.showteam, selectedRow))
      setEditFormData(Functions.filterData(Data.showteam, selectedRow))

      setMember1uuid(selectedRow.member[0].uuid)
      setEditCheckMember1(true)
      setCheckMember1(true)
      let aux = Functions.filterData(Data.showMember, selectedRow.member[0])
      if (aux.is_captain === true) {
        setIsCaptain(1)
      }
      if (aux.is_boat_pilot === true) {
        setIsBoatPilot(1)
      }
      setMember1({
        ...aux,
        birth_date: Functions.maskDate(aux.birth_date)
      })
      setEditMember1({
        ...aux,
        birth_date: Functions.maskDate(aux.birth_date)
      })

      setMember2uuid(selectedRow.member[1].uuid)
      setEditCheckMember2(true)
      setCheckMember2(true)
      aux = Functions.filterData(Data.showMember, selectedRow.member[1])
      if (aux.is_captain === true) {
        setIsCaptain(2)
      }
      if (aux.is_boat_pilot === true) {
        setIsBoatPilot(2)
      }
      setMember2({
        ...aux,
        birth_date: Functions.maskDate(aux.birth_date)
      })
      setEditMember2({
        ...aux,
        birth_date: Functions.maskDate(aux.birth_date)
      })

      if (selectedRow?.member[2]?.uuid) {
        setMember3uuid(selectedRow.member[2].uuid)
        setEditCheckMember3(true)
        setCheckMember3(true)
        aux = Functions.filterData(Data.showMember, selectedRow.member[2])
        if (aux.is_captain === true) {
          setIsCaptain(3)
        }
        if (aux.is_boat_pilot === true) {
          setIsBoatPilot(3)
        }
        setMember3({
          ...aux,
          birth_date: Functions.maskDate(aux.birth_date)
        })
        setEditMember3({
          ...aux,
          birth_date: Functions.maskDate(aux.birth_date)
        })
      }

      if (selectedRow?.member[3]?.uuid) {
        setMember4uuid(selectedRow.member[3].uuid)
        setEditCheckMember4(true)
        setCheckMember4(true)
        aux = Functions.filterData(Data.showMember, selectedRow.member[3])
        if (aux.is_captain === true) {
          setIsCaptain(4)
        }
        if (aux.is_boat_pilot === true) {
          setIsBoatPilot(4)
        }
        setMember4({
          ...aux,
          birth_date: Functions.maskDate(aux.birth_date)
        })
        setEditMember4({
          ...aux,
          birth_date: Functions.maskDate(aux.birth_date)
        })
      }
    } else if (method === 'payment') {
      const tempData = Functions.filterData(Data.showteam, selectedRow)
      const paymentData = Functions.filterData(Data.showPayment, selectedRow)

      if (
        paymentData.payment_id !== null &&
        paymentData.payment_id !== 'Null'
      ) {
        setNewPayment(false)
        setTypePayment(paymentData.payment_type)
        setPaymentDate(Functions.maskDate(paymentData.payment_date))
        setPaymentId(paymentData.payment_id)
      }
     
      setFormData(tempData)
    }
  }, [method])

  React.useEffect(() => {
    configToken()
    setMsg('')
    setIsSubmit(false)
  }, [])

  React.useEffect(() => {
    if (rows.length === 0 && config !== null) {
      loadData()
    }
  }, [config])

  React.useEffect(() => {
    if (member1?.is_captain && member1.is_captain === true) {
      setMember2({ ...member2, is_captain: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_captain: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_captain: false })
      }
    }
  }, [member1?.is_captain])

  React.useEffect(() => {
    if (member2?.is_captain && member2.is_captain === true) {
      setMember1({ ...member1, is_captain: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_captain: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_captain: false })
      }
    }
  }, [member2?.is_captain])

  React.useEffect(() => {
    if (member3?.is_captain && member3.is_captain === true) {
      setMember1({ ...member1, is_captain: false })
      setMember2({ ...member2, is_captain: false })
      if (checkMember4 === true) {
        setMember4({ ...member4, is_captain: false })
      }
    }
  }, [member3?.is_captain])

  React.useEffect(() => {
    if (member4?.is_captain && member4.is_captain === true) {
      setMember1({ ...member1, is_captain: false })
      setMember2({ ...member2, is_captain: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_captain: false })
      }
    }
  }, [member4?.is_captain])

  React.useEffect(() => {
    if (member1?.is_boat_pilot && member1.is_boat_pilot === true) {
      setMember2({ ...member2, is_boat_pilot: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_boat_pilot: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_boat_pilot: false })
      }
    }
  }, [member1?.is_boat_pilot])

  React.useEffect(() => {
    if (member2?.is_boat_pilot && member2.is_boat_pilot === true) {
      setMember1({ ...member1, is_boat_pilot: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_boat_pilot: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_boat_pilot: false })
      }
    }
  }, [member2?.is_boat_pilot])

  React.useEffect(() => {
    if (member3?.is_boat_pilot && member3.is_boat_pilot === true) {
      setMember1({ ...member1, is_boat_pilot: false })
      setMember2({ ...member2, is_boat_pilot: false })
      if (checkMember4 === true) {
        setMember4({ ...member4, is_boat_pilot: false })
      }
    }
  }, [member3?.is_boat_pilot])

  React.useEffect(() => {
    if (member4?.is_boat_pilot && member4.is_boat_pilot === true) {
      setMember1({ ...member1, is_boat_pilot: false })
      setMember2({ ...member2, is_boat_pilot: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_boat_pilot: false })
      }
    }
  }, [member4?.is_boat_pilot])

  React.useEffect(() => {
    if (isCaptain === 1) {
      setMember2({ ...member2, is_captain: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_captain: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_captain: false })
      }
    } else if (isCaptain === 2) {
      setMember1({ ...member1, is_captain: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_captain: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_captain: false })
      }
    } else if (isCaptain === 3) {
      setMember1({ ...member1, is_captain: false })
      setMember2({ ...member2, is_captain: false })
      if (checkMember4 === true) {
        setMember4({ ...member4, is_captain: false })
      }
    } else if (isCaptain === 4) {
      setMember1({ ...member1, is_captain: false })
      setMember2({ ...member2, is_captain: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_captain: false })
      }
    }
  }, [isCaptain])

  React.useEffect(() => {
    if (isBoatPilot === 1) {
      setMember2({ ...member2, is_boat_pilot: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_boat_pilot: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_boat_pilot: false })
      }
    } else if (isBoatPilot === 2) {
      setMember1({ ...member1, is_boat_pilot: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_boat_pilot: false })
      }
      if (checkMember4 === true) {
        setMember4({ ...member4, is_boat_pilot: false })
      }
    } else if (isBoatPilot === 3) {
      setMember1({ ...member1, is_boat_pilot: false })
      setMember2({ ...member2, is_boat_pilot: false })
      if (checkMember4 === true) {
        setMember4({ ...member4, is_boat_pilot: false })
      }
    } else if (isBoatPilot === 4) {
      setMember1({ ...member1, is_boat_pilot: false })
      setMember2({ ...member2, is_boat_pilot: false })
      if (checkMember3 === true) {
        setMember3({ ...member3, is_boat_pilot: false })
      }
    }
  }, [isBoatPilot])

  React.useEffect(() => {
    if (selectedRow) {
      if (method === 'show') {
        setFormData(Functions.filterData(Data.showteam, selectedRow))
        let aux = Functions.filterData(Data.showPayment, selectedRow)
        if (aux.payment_date !== null && aux.payment_date !== 'Null') {
          setPaymentData({
            ...aux,
            payment_date: Functions.maskDate(aux.payment_date)
          })
        }
        aux = Functions.filterData(Data.showMember, selectedRow.member[0])
        setMember1({ ...aux, birth_date: Functions.maskDate(aux.birth_date) })
        aux = Functions.filterData(Data.showMember, selectedRow.member[1])
        setMember2({ ...aux, birth_date: Functions.maskDate(aux.birth_date) })
        if (selectedRow?.member[2]) {
          aux = Functions.filterData(Data.showMember, selectedRow.member[2])
          setMember3({ ...aux, birth_date: Functions.maskDate(aux.birth_date) })
        }
        if (selectedRow?.member[3]) {
          aux = Functions.filterData(Data.showMember, selectedRow.member[3])
          setMember4({ ...aux, birth_date: Functions.maskDate(aux.birth_date) })
        }
      }
    }
  }, [selectedRow])

  // React.useEffect(() => {
  //   const { token } = localStorage.getItem('Fipe2023@painel')

  //   if (!token) {
  //     navigate(routes.login)
  //   }
  // }, [])

  return (
    <>
      <Components.Header title = "Motorizada"/>
      <Box sx={styles.Container}>
        <CssBaseline />

        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Total:
            </Typography>
            <Typography
              variant="h4"
              mb={2}
              ml={2}
              color={theme.palette.primary.main}
            >
              {rows.length} inscritos
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Pagantes:
            </Typography>
            <Typography
              variant="h4"
              mb={2}
              ml={2}
              color={theme.palette.primary.main}
            >
              {countPayment} pagos
            </Typography>
          </Box>
        </Box>

        {method === 'show' ? (
          <>
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Visualizar Time
            </Typography>

            <Components.Form
              data={Data.showteam}
              isSubmit={isSubmit}
              value={formData}
              onChange={handleChangeForm}
            />

            <Typography variant="h5" mb={2} color={theme.palette.primary.main}>
              Dados de Pagamento
            </Typography>

            <Components.Form
              data={Data.showPayment}
              isSubmit={isSubmit}
              value={paymentData}
              onChange={handleChangeForm}
            />

            <Typography variant="h5" mb={2} color={theme.palette.primary.main}>
              Membro 1
            </Typography>

            <Components.Form
              data={Data.showMember}
              isSubmit={isSubmit}
              value={member1}
              onChange={TeamMemberChangeForm1}
            />

            <Typography variant="h5" mb={2} color={theme.palette.primary.main}>
              Membro 2
            </Typography>

            <Components.Form
              data={Data.showMember}
              isSubmit={isSubmit}
              value={member2}
              onChange={TeamMemberChangeForm2}
            />

            {member3?.name && (
              <>
                <Typography
                  variant="h5"
                  mb={2}
                  color={theme.palette.primary.main}
                >
                  Membro 3
                </Typography>

                <Components.Form
                  data={Data.showMember}
                  isSubmit={isSubmit}
                  value={member3}
                  onChange={TeamMemberChangeForm3}
                />
              </>
            )}
            {member4?.name && (
              <>
                <Typography
                  variant="h5"
                  mb={2}
                  color={theme.palette.primary.main}
                >
                  Membro 4
                </Typography>

                <Components.Form
                  data={Data.showMember}
                  isSubmit={isSubmit}
                  value={member4}
                  onChange={TeamMemberChangeForm4}
                />
              </>
            )}
          </>
        ) : method === 'edit' ? (
          <>
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Editar Time
            </Typography>

            <Components.Form
              data={Data.editTeam}
              isSubmit={isSubmit}
              value={formData}
              onChange={handleChangeForm}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkMember1}
                  onChange={e =>
                    e.target.checked === true
                      ? refreshValue(1)
                      : setCheckMember1(e.target.checked)
                  }
                />
              }
              label={
                checkMember1 === false
                  ? 'Habilitar Membro 1'
                  : 'Desabilitar Membro 1'
              }
            />

            {checkMember2 === true && (
              <>
                <Typography
                  variant="h5"
                  mb={2}
                  color={theme.palette.primary.main}
                >
                  Membro 1
                </Typography>

                <Components.Form
                  data={Data.editMember}
                  isSubmit={isSubmit}
                  value={member1}
                  onChange={TeamMemberChangeForm1}
                  uuid={member1uuid}
                />
              </>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkMember2}
                  onChange={e =>
                    e.target.checked === true
                      ? refreshValue(2)
                      : setCheckMember2(e.target.checked)
                  }
                />
              }
              label={
                checkMember2 === false
                  ? 'Habilitar Membro 2'
                  : 'Desabilitar Membro 2'
              }
            />

            {checkMember2 === true && (
              <>
                <Typography
                  variant="h5"
                  mb={2}
                  color={theme.palette.primary.main}
                >
                  Membro 2
                </Typography>

                <Components.Form
                  data={Data.editMember}
                  isSubmit={isSubmit}
                  value={member2}
                  onChange={TeamMemberChangeForm2}
                  uuid={member2uuid}
                />
              </>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkMember3}
                  onChange={e =>
                    e.target.checked === true
                      ? refreshValue(3)
                      : setCheckMember3(e.target.checked)
                  }
                />
              }
              label={
                checkMember3 === false
                  ? 'Habilitar Membro 3'
                  : 'Desabilitar Membro 3'
              }
            />

            {checkMember3 === true && (
              <>
                <Typography
                  variant="h5"
                  mb={2}
                  color={theme.palette.primary.main}
                >
                  Membro 3
                </Typography>

                <Components.Form
                  data={Data.editMember}
                  isSubmit={isSubmit}
                  value={member3}
                  onChange={TeamMemberChangeForm3}
                  uuid={member3uuid}
                />
              </>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkMember4}
                  onChange={e =>
                    e.target.checked === true
                      ? refreshValue(4)
                      : setCheckMember4(e.target.checked)
                  }
                />
              }
              label={
                checkMember4 === false
                  ? 'Habilitar Membro 4'
                  : 'Desabilitar Membro 4'
              }
            />

            {checkMember4 === true && (
              <>
                <Typography
                  variant="h5"
                  mb={2}
                  color={theme.palette.primary.main}
                >
                  Membro 4
                </Typography>

                <Components.Form
                  data={Data.editMember}
                  isSubmit={isSubmit}
                  value={member4}
                  onChange={TeamMemberChangeForm4}
                  uuid={member4uuid}
                />
              </>
            )}
          </>
        ) : method === 'payment' ? (
          <>
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Comprovante de Pagamento
            </Typography>

            <Components.Form
              data={Data.payment}
              type="payament"
              isSubmit={isSubmit}
              value={formData}
              onChange={handleChangeForm}
            />

            <Box sx={{ width: '200px' }}>
              <FormControl
                fullWidth
                error={isSubmit && Validators.Required(typePayment).err}
              >
                <InputLabel>Tipo de Pagamento</InputLabel>
                <Select
                  label="Tipo de Pagamento"
                  value={typePayment}
                  onChange={e => setTypePayment(e.target.value)}
                >
                  <MenuItem value="Boleto">Boleto</MenuItem>
                  <MenuItem value="Pix">Pix</MenuItem>
                </Select>
                <FormHelperText>
                  {isSubmit && Validators.Required(typePayment).msg}
                </FormHelperText>
              </FormControl>
              <br />

              <>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={e => setPhoto(e.currentTarget.files[0])}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {photo ? (
                      <Box
                        component="img"
                        sx={{ height: 108, width: 108 }}
                        alt="Imagem de Perfil"
                        src={
                          typeof photo === 'string'
                            ? `${process.env.REACT_APP_API_URL_BACKEND}/file/${photo}/?t=${photo}`
                            : URL.createObjectURL(photo)
                        }
                      />
                    ) : (
                      <PaidIcon
                        sx={{
                          fontSize: 108,
                          color: theme.palette.primary.main,
                          alignSelf: 'center',
                          justifySelf: 'center'
                        }}
                      />
                    )}
                    <Button variant="contained" size="large" component="span">
                      Carregar Foto
                    </Button>
                  </Box>
                </label>
              </>
            </Box>
            <br />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Identificador de Pagamento"
                sx={{ display: 'flex', textAlign: 'center', width: '400px' }}
                error={isSubmit && Validators.Required(paymentId).err}
                helperText={isSubmit && Validators.Required(paymentId).msg}
                value={paymentId}
                onChange={e => {
                  setPaymentId(e.target.value)
                }}
                inputProps={{ maxLength: 255 }}
              />

              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Data do Pagamento"
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  width: '200px',
                  marginLeft: '20px'
                }}
                error={isSubmit && Validators.ValidatorDate(paymentDate).err}
                helperText={
                  isSubmit && Validators.ValidatorDate(paymentDate).msg
                }
                value={Mask.DateMask(paymentDate)}
                onChange={e => {
                  setPaymentDate(e.target.value)
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Box>
          </>
        ) : (
          <Components.Table
            title={Data.localTable.title}
            rows={rows}
            columns={Data.localTable.columns}
            setMethod={setMethod}
            setFormData={setSelectedRow}
            setUuid={setUuid}
            payament={true}
            link={links.motorizada}
            new={true}
          />
        )}

        {method !== 'table' && method !== 'delete' && (
          <Stack spacing={5} direction="row-reverse">
            {method === 'show' && (
              <Button
                size="large"
                variant="contained"
                onClick={() => setMethod('edit')}
              >
                Editar
              </Button>
            )}
            <Button
              size="large"
              variant={method === 'show' ? 'outlined' : 'contained'}
              onClick={() =>
                method === 'show' ? setMethod('table') : handleSubmit()
              }
            >
              {method === 'show'
                ? 'Voltar'
                : newPayment === true
                ? 'Confirmar'
                : 'Apagar'}
            </Button>
            {method !== 'show' && (
              <Button
                size="large"
                variant="outlined"
                onClick={() => setMethod('table')}
              >
                Cancelar
              </Button>
            )}
          </Stack>
        )}
        <Box mb={2} />

        <Components.Alert
          open={openAlert}
          setOpen={setOpenAlert}
          alert={typeAlert}
          msg={msg}
          delete={deleteData}
        />
      </Box>
      <Components.Footer />
    </>
  )
}

export default PainelMotorizada
