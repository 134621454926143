import { uniqueId } from 'lodash'

export const columns = [
  {
    key: uniqueId(),
    label: 'Uuid',
    name: 'uuid',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Nome do Time',
    name: 'name',
    width: 200,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    width: 180,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Pagamento',
    name: 'payment_id',
    width: 100,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'País',
    name: 'country',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Cidade',
    name: 'city',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'CEP',
    name: 'zip_code',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Complemento',
    name: 'complement',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Membro',
    name: 'member1',
    width: 200,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'CPF Membro',
    name: 'member1_Cpf',
    width: 150,
    filter: true,
    hide: false,
    mask: 'Cpf'
  }
  // {
  //   key: uniqueId(),
  //   label: 'Membro 2',
  //   name: 'member2',
  //   width: 200,
  //   hide: false
  // },
  // {
  //   key: uniqueId(),
  //   label: 'CPF Membro 2',
  //   name: 'member2_Cpf',
  //   width: 150,
  //   hide: false,
  //   mask: 'Cpf'
  // }
]

export const showPayment = [
  {
    key: uniqueId(),
    label: 'Tipo de Pagamento',
    name: 'payment_type',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Comprovate de Pagamento',
    name: 'file_payment_voucher',
    type: 'ShowImg',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Identificador do Pagamento',
    name: 'payment_id',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Data do Pagamento',
    name: 'payment_date',
    type: 'TextShow',
    mask: 'DateMask',
    measures: {
      xs: 12,
      sm: 12
    }
  }
]

export const showteam = [
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Nome do Time',
    name: 'name',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'País',
    name: 'country',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Cidade',
    name: 'city',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'CEP',
    name: 'zip_code',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Complemento',
    name: 'complement',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  }
]

export const showMember = [
  {
    key: uniqueId(),
    label: 'Foto do Participante',
    name: 'photo',
    type: 'ShowImg',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 2,
      sm: 2
    }
  },
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'name',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'CPF',
    name: 'cpf',
    type: 'TextShow',
    mask: 'Cpf',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Telefone',
    name: 'phone',
    type: 'TextShow',
    mask: 'Phone',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Data de Nascimento',
    name: 'birth_date',
    type: 'TextShow',
    mask: 'DateMask',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Licença de Pesca',
    name: 'fishing_license',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Email',
    name: 'email',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Tamanho da Camisa',
    name: 'shirt_size',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Sexo',
    name: 'sex',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  }
]

export const editTeam = [
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    type: 'TextShow',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Nome do Time',
    name: 'name',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'País',
    name: 'country',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'CEP',
    name: 'zip_code',
    type: 'TextField',
    mask: 'Cep',
    validator: 'Cep',
    max: 10,
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    max: 2,
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Cidade',
    name: 'city',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 6,
      sm: 3
    }
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 6,
      sm: 3
    }
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 5
    }
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    type: 'TextField',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 4,
      sm: 1
    }
  },
  {
    key: uniqueId(),
    label: 'Complemento',
    name: 'complement',
    type: 'TextField',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 8,
      sm: 12
    }
  }
]

export const editMember = [
  {
    key: uniqueId(),
    label: 'Foto do Participante',
    name: 'photo',
    type: 'FileImg',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 3,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'name',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 8
    }
  },
  {
    key: uniqueId(),
    label: 'CPF',
    name: 'cpf',
    type: 'TextField',
    mask: 'Cpf',
    validator: 'Cpf',
    measures: {
      xs: 12,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Telefone',
    name: 'phone',
    type: 'TextField',
    mask: 'Phone',
    validator: 'Phone',
    max: 19,
    measures: {
      xs: 12,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Data de Nascimento',
    name: 'birth_date',
    type: 'TextField',
    mask: 'DateMask',
    validator: 'Date16',
    measures: {
      xs: 12,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Licença de Pesca',
    name: 'fishing_license',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Email',
    name: 'email',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 6
    }
  },
  {
    key: uniqueId(),
    label: 'Tamanho da Camisa',
    name: 'shirt_size',
    type: 'Select',
    mask: 'Text',
    validator: 'Required',
    data: [
      {
        uuid: 'PP',
        name: 'PP'
      },
      {
        uuid: 'P',
        name: 'P'
      },
      {
        uuid: 'M',
        name: 'M'
      },
      {
        uuid: 'G',
        name: 'G'
      },
      {
        uuid: 'GG',
        name: 'GG'
      }
    ],
    measures: {
      xs: 12,
      sm: 3
    }
  },
  {
    key: uniqueId(),
    label: 'Sexo',
    name: 'sex',
    type: 'Select',
    mask: 'Text',
    validator: 'Required',
    data: [
      {
        uuid: 'F',
        name: 'Feminino'
      },
      {
        uuid: 'M',
        name: 'Masculino'
      }
    ],
    measures: {
      xs: 6,
      sm: 3
    }
  }
]

export const payment = [
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Nome do Time',
    name: 'name',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  }
]

export const localTable = {
  title: 'Pesca Caiaque',
  baseUrl: '/contacts',
  columns
}
