// import background from '../../assets/img/background.png'

export const Container = {
  display: 'flex',
  height: '100vh',
  width: '100vw',
  // backgroundImage: `url(${background})`,
  alignItems: 'center',
  justifyContent: 'center'
}

export const Box = {
  borderWidth: '1px',
  backgroundColor: '#fff',
  padding: '25px',
  borderRadius: '10px'
}
