export const Captalize = value => {
  value = value.toLowerCase()
  const aux = value.split(' ')
  for (let i = 0; i < aux.length; i += 1) {
    if (aux[i].length > 3) {
      aux[i] = aux[i].charAt(0).toUpperCase() + aux[i].slice(1)
    } else if (aux[i].length === 3 && aux[i].charAt(2) !== 's') {
      aux[i] = aux[i].charAt(0).toUpperCase() + aux[i].slice(1)
    }
  }

  return aux.join(' ')
}

export const Cpf = value => {
  if (value) {
    value = value.replace(/[^0-9]/g, '')
    const temp = value.split('')
    let aux = ''

    for (let i = 0; i < temp.length; i += 1) {
      if (i === 3 || i === 6) {
        aux = `${aux}.${temp[i]}`
      } else if (i === 9) {
        aux = `${aux}-${temp[i]}`
      } else if (i < 11) {
        aux += temp[i]
      }
    }
    return aux
  } else {
    return ''
  }
}

export const Cnpj = value => {
  value = value.replace(/[^0-9]/g, '')
  const temp = value.split('')
  let aux = ''

  for (let i = 0; i < temp.length; i += 1) {
    if (i === 2 || i === 5) {
      aux = `${aux}.${temp[i]}`
    } else if (i === 8) {
      aux = `${aux}/${temp[i]}`
    } else if (i === 12) {
      aux = `${aux}-${temp[i]}`
    } else if (i < 14) {
      aux += temp[i]
    }
  }
  return aux
}

export const Cep = value => {
  value = value.replace(/[^0-9]/g, '')
  const temp = value.split('')
  let aux = ''

  for (let i = 0; i < temp.length; i += 1) {
    if (i === 2) {
      aux = `${aux}.${temp[i]}`
    } else if (i === 5) {
      aux = `${aux}-${temp[i]}`
    } else if (i < 8) {
      aux += temp[i]
    }
  }
  return aux
}

export const Phone = value => {
  value = value.replace(/[^0-9]/g, '')
  const temp = value.split('')
  let aux = ''

  for (let i = 0; i < temp.length; i += 1) {
    if (i === 0) {
      aux = `+${aux}${temp[i]}`
    } else if (i === 2) {
      aux = `${aux} (${temp[i]}`
    } else if (i === 4) {
      aux = `${aux}) ${temp[i]}`
    } else if (temp.length < 13 && i === 8) {
      aux = `${aux}-${temp[i]}`
    } else if (temp.length >= 13 && i === 9) {
      aux = `${aux}-${temp[i]}`
    } else if (i < 13) {
      aux += temp[i]
    }
  }
  return aux
}

export const Moeda = value => {
  const temp = parseFloat(value).toString().split('').reverse()
  let aux = ''

  if (temp.length < 3) {
    if (temp.length === 1) {
      return `R$: 0,0${temp[0]}`
    }
    return `R$: 0,${temp[1]}${temp[0]}`
  }
  for (let i = 0; i < temp.length; i += 1) {
    if (i === 2) {
      aux = `${aux},${temp[i]}`
    } else if (temp.length > 4 && (i + 1) % 3 === 0) {
      aux = `${aux}.${temp[i]}`
    } else {
      aux += temp[i]
    }
  }

  return `R$ ${aux.split('').reverse().join('')}`
}

export const Placa = value => {
  value = value.toUpperCase().replace('-', '')
  const temp = value.split('')
  let aux = ''

  for (let i = 0; i < temp.length; i += 1) {
    if (i === 3) {
      aux = `${aux}-${temp[i]}`
    } else if (i < 7) {
      aux += temp[i]
    }
  }
  return aux
}

export const DateMask = value => {
  value = value.toString().replace(/[^0-9]/g, '')
  const temp = value.split('')
  let aux = ''

  for (let i = 0; i < temp.length; i += 1) {
    if (i === 2) {
      aux = `${aux}/${temp[i]}`
    } else if (i === 4) {
      aux = `${aux}/${temp[i]}`
    } else if (i < 8) {
      aux += temp[i]
    }
  }
  return aux
}

export const Text = value => {
  return value
}
