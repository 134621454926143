export const links = {
  home: process.env.PUBLIC_URL + "/home",
  esportes: process.env.PUBLIC_URL + "/esportes/painel",
  senior: process.env.PUBLIC_URL + "/senior",
  seniorPainel: process.env.PUBLIC_URL + "/senior/painel",
  pcd: process.env.PUBLIC_URL + "/pcd",
  pcdPainel: process.env.PUBLIC_URL + "/pcd/painel",
  infantil: process.env.PUBLIC_URL + "/infantil",
  infantilPainel: process.env.PUBLIC_URL + "/infantil/painel",
  caiaque: process.env.PUBLIC_URL + "/caiaque",
  caiaquePainel: process.env.PUBLIC_URL + "/caiaque/painel",
  canoa: process.env.PUBLIC_URL + "/canoa",
  canoaPainel: process.env.PUBLIC_URL + "/canoa/painel",
  motorizada: process.env.PUBLIC_URL + "/motorizada",
  motorizadaPainel: process.env.PUBLIC_URL + "/motorizada/painel",
  login: process.env.PUBLIC_URL + "/",
}