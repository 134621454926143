import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Home from "../pages/Home"
import Senior from '../pages/senior'
import SeniorPainel from '../pages/seniorPainel'
import Pcd from '../pages/pcd'
import PcdPainel from '../pages/pcdPainel'
import Infantil from '../pages/infantil'
import InfantilPainel from '../pages/InfantilPainel'
import Caiaque from "../pages/caiaque"
import CaiaquePainel from "../pages/caiaquePainel"
import Canoa from "../pages/canoa"
import CanoaPainel from "../pages/canoaPainel"
import Motorizada from "../pages/motorizada"
import MotorizadaPainel from "../pages/motorizadaPainel"
import Login from "../pages/Login"
import Esporte from '../pages/esporte'

import { links } from "../routes/data"

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={links.home} element={<Home />} />
        <Route path={links.esportes} element={<Esporte />} />
        <Route path={links.senior} element={<Senior />} />
        <Route path={links.seniorPainel} element={<SeniorPainel />} />
        <Route path={links.pcd} element={<Pcd />} />
        <Route path={links.pcdPainel} element={<PcdPainel />} />
        <Route path={links.infantil} element={<Infantil />} />
        <Route path={links.infantilPainel} element={<InfantilPainel />} />
        <Route path={links.caiaque} element={<Caiaque />} />
        <Route path={links.caiaquePainel} element={<CaiaquePainel />} />
        <Route path={links.canoa} element={<Canoa />} />
        <Route path={links.canoaPainel} element={<CanoaPainel />} />
        <Route path={links.motorizada} element={<Motorizada />} />
        <Route path={links.motorizadaPainel} element={<MotorizadaPainel />} />
      </Routes>
    </Router>
  )
}

export default Index