import * as validator from "../../utils/validators"

export const formFields = { 
  name: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  district: "",
  street: "",
  number: "",
  complement: "",
  name_1: "",
  cpf_1: "",
  phone_1: "55",
  birth_date_1: "",
  fishing_license_1: "",
  email_1: "",
  shirt_size_1: "",
  is_captain_1: true,
  sex_1: "",
  photo_1: "",
  name_2: "",
  cpf_2: "",
  phone_2: "55",
  birth_date_2: "",
  fishing_license_2: "",
  email_2: "",
  shirt_size_2: "",
  is_captain_2: false,
  sex_2: "",
  photo_2: "",
}

export const validateForm = (form) => {
  let isValid = true
  
  if (validator.Required(form.name).err === true) {
    isValid = false
  } else if (validator.Required(form.country).err === true) {
    isValid = false
  } else if (validator.Required(form.state).err === true) {
    isValid = false
  } else if (validator.Required(form.city).err === true) {
    isValid = false
  } else if (validator.Required(form.zip_code).err === true) {
    isValid = false
  } else if (validator.Required(form.district).err === true) {
    isValid = false
  } else if (validator.Required(form.street).err === true) {
    isValid = false
  } else if (validator.Required(form.name_1).err === true) {
    isValid = false
  } else if (form.cpf_1 !== "" && validator.Cpf(form.cpf_1).err === true) {
    isValid = false
  } else if (validator.Phone(form.phone_1).err === true) {
    isValid = false
  } else if (validator.Date16(form.birth_date_1).err === true) {
    isValid = false
  } else if (validator.Required(form.fishing_license_1).err === true) {
    isValid = false
  } else if (validator.Required(form.email_1).err === true) {
    isValid = false
  } else if (validator.Required(form.shirt_size_1).err === true) {
    isValid = false
  } else if (validator.Required(form.sex_1).err === true) {
    isValid = false
  } else if (validator.Required(form.name_2).err === true) {
    isValid = false
  } else if (form.cpf_2 !== "" && validator.Cpf(form.cpf_2).err === true) {
    isValid = false
  } else if (validator.Phone(form.phone_2).err === true) {
    isValid = false
  } else if (validator.Date16(form.birth_date_2).err === true) {
    isValid = false
  } else if (validator.Required(form.fishing_license_2).err === true) {
    isValid = false
  } else if (validator.Required(form.email_2).err === true) {
    isValid = false
  } else if (validator.Required(form.shirt_size_2).err === true) {
    isValid = false
  } else if (validator.Required(form.sex_2).err === true) {
    isValid = false
  }

  return isValid
}

export const filterMask = (form) => {
  const tempForm = form

  if (form.cpf_1) {
    tempForm.cpf_1 = form.cpf_1.replace(/[^0-9]/g, "")
  }
  if (form.phone_1) {
    tempForm.phone_1 = form.phone_1.replace(/[^0-9]/g, "")
  }
  if (form.cpf_2) {
    tempForm.cpf_2 = form.cpf_2.replace(/[^0-9]/g, "")
  }
  if (form.phone_2) {
    tempForm.phone_2 = form.phone_2.replace(/[^0-9]/g, "")
  }
  if (form.zip_code) {
    tempForm.zip_code = form.zip_code.replace(/[^0-9]/g, "")
  }

  return tempForm
}