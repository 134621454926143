import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material"

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { differenceInYears, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentCopy from "@mui/icons-material/ContentCopy"

import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import QRCode from "react-qr-code"
import { format, parse, isValid } from 'date-fns'

import avatar from "../../assets/img/avatar.jpg"
import peixe from "../../assets/img/peixe.png"
import warning from "../../assets/img/Warning.png"

import Header from "../../components/Header"
import Footer from "../../components/Footer"

import api from "../../services/apiInfantil"

import * as mask from "../../utils/mask"
import * as validator from "../../utils/validators"

import * as styles from "./styles"
import { filterMask, formFields, validateForm } from "./data"

const Index = () => {
  const [form, setForm] = useState(formFields)
  const [newImage, setNewImage] = useState(null)
  const [submit, setSubmit] = useState(false)
  const [tempToken, setTempToken] = useState("")
  
  const [loading, setLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState({ status: "Error", msg: "", open: false })
  const [modalOpen, setModalOpen] = useState(false)
  const [controlModal, setControlModal] = useState(0)
  const [code, setCode] = useState("IN-")

  const [isButtonEnabled, setIsButtonEnabled] = useState(true)
  const [timeLeft, setTimeLeft] = useState(0)

  const timeoutRef = useRef(null)
  const intervalRef = useRef(null)

  // Modificar para valores atuais
  const MODALITY_VALUE = "350,00"
  const PIX_TYPE = "Chave"
  const PIX_KEY = "98f1bdf5-0fed-4641-99ad-4df4bfb1e1dc"

  const getCep = async (value) => {
    const tempCep = value.replace(/[^0-9]/g, "")
    if (tempCep.length === 8) {
      try {
        const url = `https://viacep.com.br/ws/${tempCep}/json/`
        const response = await axios.get(url)
        if (response.data.uf !== undefined) {
          setForm({ 
            ...form,
            state: response.data.uf,
            district: response.data.bairro,
            city: response.data.localidade,
            street: response.data.logradouro,
            complement: response.data.complemento
          })
        } else {
          setErrorStatus({ status: "error", msg: "CEP não foi Encontrado!", open: true })
        }
      } catch (error) {
        setErrorStatus({ status: "error", msg: "Problema de Conexão! Verifique se há Internet", open: true })
      }
    }
  }

  const parseDate = (dateString) => {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date())
    return isValid(parsedDate) ? parsedDate : null
  };

  // Função para converter Date para string no formato DD/MM/YYYY
  const formatDate = (date) => {
    return date ? format(date, 'yyyy-MM-dd') : ''
  }

  const categorizeAge = (birthDate) => {
    const referenceDate = new Date(2024, 6, 2)
    const birthDateParsed = parseISO(birthDate)
    const age = differenceInYears(referenceDate, birthDateParsed)

    if (age >= 6 && age <= 9) {
        return 'A'
    } else if (age >= 10 && age <= 12) {
        return 'B'
    } else {
        return null
    }
}

  const handleCLoseAlert = () => {
    setErrorStatus({ status: "error", msg: "", open: false });
  }

  const onChange = (key, value) => {
    setForm({ ...form, [key]: value })
  }
  
  const handleResend = async () => {
    setSubmit(true)
    setLoading(true)

    const isValidate = validateForm(form)
    if (isValidate === true) {
      const tempSendData = filterMask(form)
    
      const sendData = new FormData()

      if (newImage) {
        sendData.append("photo", newImage)
      }

      for (const key in tempSendData) {
        sendData.append(key, tempSendData[key])
      }

      try {
        const response = await api.post('/subscription/', sendData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response) {
          setTempToken(response?.data?.token || "")
        }

        setLoading(false)
        handleCountDown()
      } catch(error) {
        setLoading(false)
        setErrorStatus({ status: "error", msg: error.message, open: true })
      }
    }
  }

  const handleCountDown = () => {
    setIsButtonEnabled(false)
    setTimeLeft(60)

    timeoutRef.current = setTimeout(() => {
      setIsButtonEnabled(true)
      setTimeLeft(0)
    }, 60000)

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current)
          return 0
        }

        return prevTime - 1
      })
    }, 1000)
  }

  const handleSubmit = async () => {
    setSubmit(true)
    
    const isValidate = validateForm(form)
    if (isValidate === true) {
      setLoading(true)
      const tempSendData = filterMask(form)
      const sendData = new FormData()

      if (newImage) {
        sendData.append("photo", newImage)
      }

      for (const key in tempSendData) {
        sendData.append(key, tempSendData[key])
      }

      if (tempSendData.birth_date) {
        const tempCategory = categorizeAge(tempSendData.birth_date)
        console.log(tempCategory)
        if (tempCategory) {
          sendData.append("category", tempCategory)
        }
      }

      try {
        const response = await api.post('/young/', sendData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response) {
          setCode(response?.data?.subscription_number)
          setLoading(false)
          setControlModal(4)        
          setModalOpen(true)
        }
        
        // setLoading(false)
        // handleCountDown()
        // setControlModal(5)
        setModalOpen(true)
      } catch(error) {
        setLoading(false)
        setErrorStatus({ status: "error", msg: error.message, open: true })
      }
    }
  }

  const handleSubmitCode = async () => {
    if (tempToken !== '' && tempToken !== null) {
      setLoading(true)
      
      const tempSendData = filterMask(form)
      const sendData = new FormData()

      if (newImage) {
        sendData.append("photo", newImage)
      }

      for (const key in tempSendData) {
        sendData.append(key, tempSendData[key])
      }

      if (tempSendData.birth_date) {
        const tempCategory = categorizeAge(tempSendData.birth_date)
        if (tempCategory) {
          sendData.append("category", tempCategory)
        }
      }

      sendData.append("subscription_number", code)

      try {
        const response = await api.post('/subscription/', sendData, {
          headers: {
            'Authentication': tempToken,
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response) {
          setCode(response?.data?.subscription_number)
          setLoading(false)
          setControlModal(4)
        } else {
          setLoading(false)
          setErrorStatus({ status: "error", msg: "Ocorreu um erro ao realizar sua inscrição!", open: true })
        }  
      } catch(error) {
        setLoading(false)
        setErrorStatus({ status: "error", msg: error.message, open: true })
      }
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [])

  return (
    <Box>
      <Box sx={styles.mainBox}>
        <Header title="Infanto Juvenil" />

        <Box sx={styles.imputGroup}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Pescador
            </Typography>
          </Box>

          <Box fullWidth sx={styles.imageBox}>
            <Box
              sx={{
                padding: 0,
                width: "100px",
                height: "100px",
              }}
            >
              <label
                htmlFor="img"
                style={{
                  padding: 0,
                  width: "100%",
                  height: "100%",
                  margin: "5px 0",
                  display: "flex",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <img
                  style={styles.imageFormat}
                  alt="Img"
                  src={newImage ? URL.createObjectURL(newImage) : avatar}
                ></img>
              </label>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">Selecione uma Imagem!</Typography>
            </Box>
            <input
              style={{ display: "none" }}
              type="file"
              id="img"
              onChange={(e) => {
                setNewImage(e.target.files[0])
              }}
            />
          </Box>

          <TextField
            error={submit && validator.Required(form.name).err}
            helperText={submit && validator.Required(form.name).msg}
            value={form.name}
            onChange={(e) => onChange("name", e.target.value)}
            label="Nome do Pescador"
            fullWidth
          ></TextField>

          <TextField
            error={submit && validator.Required(form.mother_name).err}
            helperText={submit && validator.Required(form.mother_name).msg}
            value={form.mother_name}
            onChange={(e) => onChange("mother_name", e.target.value)}
            label="Nome da mãe"
            fullWidth
          ></TextField>

          <TextField
            error={submit && form.cpf !== "" && validator.Cpf(form.cpf).err}
            helperText={submit && form.cpf !== "" && validator.Cpf(form.cpf).msg}
            value={mask.Cpf(form.cpf)}
            onChange={(e) => onChange("cpf", e.target.value)}
            label="Cpf do Pescador"
            sx={styles.halfField}
            inputProps={{ maxLength: 14 }}
          ></TextField>

          {/* <TextField
            error={submit && validator.DataInfantil(form.birth_date).err}
            helperText={submit && validator.DataInfantil(form.birth_date).msg}
            value={mask.DateMask(form.birth_date)}
            onChange={(e) => onChange("birth_date", e.target.value)}
            label="Nascimento"
            sx={styles.halfField}
          ></TextField> */}

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Box sx={styles.halfField}>
              <DatePicker
                label="Nascimento"
                value={parseDate(form.birth_date)}
                onChange={(date) => {
                  if (date && isValid(date)) {
                    onChange("birth_date", formatDate(date));
                  }
                }}
                format="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={submit && validator.DateInfantil(form.birth_date).err}
                    helperText={submit && validator.DateInfantil(form.birth_date).msg}
                    value={form.birth_date}
                    onChange={(e) => onChange("birth_date", e.target.value)}             
                  />
                )}
                sx={{ width: "100%" }}
              />
            </Box>
          </LocalizationProvider>

          <FormControl sx={styles.halfField}>
            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.sex}
              label="Sexo"
              error={submit && form.sex === ""}
              onChange={(e) => onChange("sex", e.target.value)}
            >
              <MenuItem value={"M"}>Masculino</MenuItem>
              <MenuItem value={"F"}>Feminino</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={styles.halfField}>
            <InputLabel id="demo-simple-select-label">
              Tamanho da Camiseta
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.shirt_size}
              label="Tamanho da Camiseta"
              error={submit && form.shirt_size === ""}
              onChange={(e) => onChange("shirt_size", e.target.value)}
            >
              <MenuItem value={"6"}>6</MenuItem>
              <MenuItem value={"8"}>8</MenuItem>
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"12"}>12</MenuItem>
              <MenuItem value={"14"}>14</MenuItem>
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: "5px",
            }}
          >
            <Typography variant="subtitle1">Infantil:</Typography>
            <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
              De 6 à 9 anos
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: "5px",
            }}
          >
            <Typography variant="subtitle1">Juvenil:</Typography>
            <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
              De 10 à 12 anos
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.imputGroup}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Responsável
            </Typography>
          </Box>

          <TextField
            error={submit && validator.Required(form.responsible_name).err}
            helperText={submit && validator.Required(form.responsible_name).msg}
            value={form.responsible_name}
            onChange={(e) => onChange("responsible_name", e.target.value)}
            label="Nome do Responsável"
            fullWidth
          ></TextField>

          <TextField
            error={submit && form.responsible_cpf !== "" && validator.Cpf(form.responsible_cpf).err}
            helperText={submit && form.responsible_cpf !== "" && validator.Cpf(form.responsible_cpf).msg}
            value={mask.Cpf(form.responsible_cpf)}
            onChange={(e) => onChange("responsible_cpf", e.target.value)}
            label="Cpf do Responsável"
            sx={styles.halfField}
            inputProps={{ maxLength: 14 }}
          ></TextField>

          <TextField
            error={submit && validator.Phone(form.responsible_phone).err}
            helperText={submit && validator.Phone(form.responsible_phone).msg}
            value={mask.Phone(form.responsible_phone)}
            onChange={(e) => onChange("responsible_phone", e.target.value)}
            label="Celular"
            sx={styles.halfField}
            inputProps={{ maxLength: 19 }}
          ></TextField>
          <TextField
            error={submit && validator.Required(form.responsible_email).err}
            helperText={
              submit && validator.Required(form.responsible_email).msg
            }
            value={form.responsible_email}
            onChange={(e) => onChange("responsible_email", e.target.value)}
            label="Email"
            fullWidth
          ></TextField>
        </Box>
        
        <Box sx={styles.imputGroup}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Endereço
            </Typography>
          </Box>

          <TextField
            error={submit && validator.Required(form.zip_code).err}
            helperText={submit && validator.Required(form.zip_code).msg}
            value={mask.Cep(form.zip_code)}
            onChange={(e) => onChange("zip_code", e.target.value)}
            onBlur={(e) => {
              if (e.target.value.length === 10) {
                getCep(e.target.value)
              }
            }}
            label="Cep"
            sx={styles.halfField}
            inputProps={{ maxLength: 10 }}
          ></TextField>

          <TextField
            error={submit && validator.Required(form.country).err}
            helperText={submit && validator.Required(form.country).msg}
            value={form.country}
            onChange={(e) => onChange("country", e.target.value)}
            label="País"
            sx={styles.halfField}
          ></TextField>

          <TextField
            error={submit && validator.Required(form.state).err}
            helperText={submit && validator.Required(form.state).msg}
            value={form.state}
            onChange={(e) => onChange("state", e.target.value)}
            label="Estado"
            sx={styles.halfField}
            inputProps={{ maxLength: 2 }}
          ></TextField>

          <TextField
            error={submit && validator.Required(form.city).err}
            helperText={submit && validator.Required(form.city).msg}
            value={form.city}
            onChange={(e) => onChange("city", e.target.value)}
            label="Cidade"
            sx={styles.halfField}
          ></TextField>

          <TextField
            error={submit && validator.Required(form.district).err}
            helperText={submit && validator.Required(form.district).msg}
            value={form.district}
            onChange={(e) => onChange("district", e.target.value)}
            label="Bairro"
            fullWidth
          ></TextField>

          <TextField
            error={submit && validator.Required(form.street).err}
            helperText={submit && validator.Required(form.street).msg}
            value={form.street}
            onChange={(e) => onChange("street", e.target.value)}
            label="Rua"
            sx={styles.halfField}
          ></TextField>

          <TextField
            error={submit && form.number.err}
            helperText={submit && form.number.msg}
            value={form.number}
            onChange={(e) => onChange("number", e.target.value)}
            label="Número"
            sx={styles.halfField}
          ></TextField>

          <TextField
            error={submit && form.complement.err}
            helperText={submit && form.complement.msg}
            value={form.complement}
            onChange={(e) => onChange("complement", e.target.value)}
            label="Complemento"
            fullWidth
          ></TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",

            alignItems: "flex-start",
          }}
        >
          <Button variant="contained" color="error" onClick={() => window.history.back()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
          >
            Concluir
          </Button>
        </Box>

        <Footer />
      </Box>

      <Backdrop
        open={loading}
        sx={{
          color: "#333333",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          pointerEvents: "none",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open={errorStatus.open}
        onClose={handleCLoseAlert}
      >
        <Box sx={styles.ErrorModal}>
          <Typography
            variant="h5"
            align="center"
            width={"100%"}
            sx={{ fontWeight: "bold" }}
          >
            Erro
          </Typography>
          
          <CancelIcon 
            sx={{...styles.imageBox,  fontSize: 100, margin: "20px 0px"}} 
            color="error" 
          />

          <Typography component="p" align="center" width={"100%"}>
            {errorStatus.msg}
          </Typography>

          <Button
            sx={{ display: "flex", alignSelf: "center", marginTop: "20px" }}
            variant="contained" 
            color="primary" 
            onClick={handleCLoseAlert}
          >
            Confirmar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box sx={styles.alertBox}>
        {controlModal === 1 ? (
          <Box sx={styles.imputGroup}>
            <Box sx={styles.imageBox}>
              <img src={warning} alt="Atenção" style={{ width: "140px" }} />
            </Box>
            <Typography
              variant="h5"
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold" }}
            >
              AINDA NÃO ACABOU
            </Typography>
            <Typography
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold", fontSize: "1rem" }}
            >
              CÓDIGO DE CONFIRMAÇÃO
            </Typography>
            <Typography component="p" align="center" width={"100%"}>
              Insira abaixo o código enviado para o e-mail {form.email} e para o
              Whatsapp {form.tel} para confirmar sua solicitação!
            </Typography>
            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(code).err}
                helperText={submit && validator.Required(code).msg}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                label="Código"
                fullWidth
              ></TextField>
            </Box>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ margin: "10px" }}
                onClick={() => {
                  setCode("")
                  setTempToken("")
                  setModalOpen(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ margin: "10px" }}
                onClick={handleSubmitCode}
              >
                Concluir
              </Button>
            </Box>
            <Typography component="p" align="center" width={"100%"}>
              Não recebeu o código?
              <Button
                variant="text"
                disabled={!isButtonEnabled}
                onClick={() => {
                  handleResend()
                }}
              >
                {isButtonEnabled ? "Reenviar" : `Reenviar (${timeLeft}s)`}
              </Button>
            </Typography>
          </Box>
        ) : controlModal === 2 ? (
          <Box sx={styles.imputGroup}>
            <Box sx={styles.imageBox}>
              <img
                src="https://thumbs.gfycat.com/QuaintLikelyFlyingfish-max-1mb.gif"
                alt="Confirm"
                style={{ width: "100%" }}
              />
            </Box>
              <Typography
                variant="h5"
                align="center"
                width={"100%"}
                sx={{ fontWeight: "bold" }}
              >
                SOLICITAÇÃO CONCLUÍDA!
              </Typography>

              <Typography
                component="p"
                textAlign="center"
                width={"100%"}
                sx={{ marginTop: "20px" }}
              >
                Sua <b>solicitação</b> de inscrição na modalidade{" "}
                <b>PESCA MOTORIZADA</b> do 40º Fipe foi realizada!
              </Typography>

              <Typography
                component="h5"
                variant="h5"
                textAlign="center"
                fontWeight="bold"
                width={"100%"}
                sx={{ marginTop: "20px" }}
              >
                Efetue o pagamento para confirmar sua inscrição!
              </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setControlModal(3);
                }}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        ) : controlModal === 3 ? (
          <Box sx={styles.imputGroup}>
            <Box sx={styles.imageBox}>
              <img
                src="https://devtools.com.br/img/pix/logo-pix-png-icone-520x520.png"
                alt="Confirm"
                style={{ width: "200px" }}
              />
            </Box>
            <Typography
              variant="h5"
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold" }}
            >
              PAGAMENTO
            </Typography>
            <Typography
              component="p"
              textAlign="center"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              <b>Realize o pagamento</b> do valor de <b>R${MODALITY_VALUE}</b>{" "}
              para a chave <b>PIX</b> abaixo!
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              textAlign="center"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              {PIX_TYPE}: <b id="key">{PIX_KEY}</b>{" "}
              <IconButton
                sx={{ margin: "0", padding: "0" }}
                aria-label="copiar"
                color="success"
                onClick={() => {
                  setErrorStatus({
                    status: "success",
                    msg: "Chave PIX Copiada!",
                    open: true,
                  });
                  const content = document.getElementById("key").innerText;
                  navigator.clipboard.writeText(content);
                }}
              >
                <ContentCopy />
              </IconButton>
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              textAlign="center"
              fontWeight="bold"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              Seu código de inscrição é <b>{code}</b>
            </Typography>
            <Typography
              component="p"
              textAlign="center"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              Encaminhe o <b>comprovante do pagamento</b> e o código <b>{code}</b>{" "}
              para o número <b id="tel">(65)9361-4982</b>{" "}
              <IconButton
                sx={{ margin: "0", padding: "0" }}
                aria-label="copiar"
                color="success"
                onClick={() => {
                  setErrorStatus({
                    status: "success",
                    msg: "Número Copiado!",
                    open: true,
                  });
                  const content = document.getElementById("tel").innerText;
                  navigator.clipboard.writeText(content);
                }}
              >
                <ContentCopy />
              </IconButton>{" "}
              ou clique no botão abaixo e vá direto para nosso canal de
              comunicação.
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px"}}
            >
              <Button  variant="contained" color="error"   sx={{margin: "0 10px"}} onClick={()=>{
                setCode("")
                setTempToken("")
                setControlModal(0)
                setModalOpen(false)
              }}>
                  FECHAR
                </Button>
              <Link
                href={`https://api.whatsapp.com/send?phone=556593614982&text=Ol%C3%A1!%20%0A%F0%9F%92%B2Segue%20o%20comprovante%20de%20pagamento%20da%20inscri%C3%A7%C3%A3o.%0A%0AEquipe:%20${form.name}%20%0AC%C3%B3digo:%20${code}`}
                underline="none"
                sx={{margin: "0 10px"}}
              >
                <Button variant="contained" color="success">
                  enviar
                </Button>
              </Link>
            </Box>
          </Box>
        ) : controlModal === 4 ? (
          <Box sx={styles.imputBox}>
            <Box>
              <CheckCircleIcon 
                sx={{...styles.imageBox,  fontSize: 150}} 
                color="success" 
              />
            </Box>
            <Box sx={{ margin: "20px 0px" }}>
              <Typography sx={{textAlign: "center", marginBottom: "20px"}} variant="h6" fontWeight="bold">
                INSCRIÇÃO CONFIRMADA
              </Typography>

              <Typography variant="subtitle1" fontWeight="bold">
                Parabéns, o participante {form.name} foi cadastrado com sucesso!
              </Typography>

              <Typography sx={{textAlign: "center", margin: "10px"}}>
                Acesse o comprovante abaixo!
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setControlModal(5)
              }}
            >
              Comprovante
            </Button>
          </Box>
        ) : controlModal === 5 ? (
          <Box style={styles.imputBox}>
            <Box sx={{ width: "100%" }} id="print">
              <Box sx={styles.session}>
                <Typography variant="h6" fontWeight="bold">
                  COMPROVANTE DE INSCRIÇÃO
                </Typography>

                <div style={{ display: "flex", alignItems: "center", height: 100, width: 100 }}>
                  <img style={{ height: "100%", width: "100%" }} src={peixe} alt="perfil" />
                </div>

                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {form.name}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="h5">Categoria: </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      INFANTO JUVENIL
                    </Typography>
                  </Box>
                </Box>              
              </Box>

              <Box sx={styles.session}>
                <Box sx={styles.texts}>
                  <Box sx={styles.format}>
                    <Typography variant="h6">CPF: </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {mask.Cpf(form.cpf)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ ...styles.session, borderBottom: "none" }}>
                <Typography variant="h6" fontWeight="bold">
                  Código de inscrição
                </Typography>

                <Typography variant="h3" fontWeight="bold">
                  {code}
                </Typography>

                <Box maxWidth={"160px"}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={code}
                    viewBox={`0 0 256 256`}
                  />
                </Box>

                <Typography variant="subtitle1">
                  TIRE UM PRINT DESTA TELA*
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ marginTop: "10px" }}
                  onClick={() => window.location.reload()}
                >
                  Voltar ao Inicio
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
        </Box>
      </Modal>
    </Box>
  )
}

export default Index
