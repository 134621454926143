import * as validator from "../../utils/validators"

export const formFields = {
  responsible_name: "",
  responsible_cpf: "",
  responsible_phone: "55",
  responsible_email: "",
  mother_name: "",
  name: "",
  cpf: "",
  birth_date: "",
  photo: "",
  sex: "",
  shirt_size: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  district: "",
  street: "",
  number: "",
  complement: "",
}

export const validateForm = (form) => {
  let isValid = true
  
  if (validator.Required(form.responsible_name).err === true) {
    isValid = false
  } else if (validator.Required(form.responsible_email).err === true) {
    isValid = false
  } else if (form.responsible_cpf !== "" && validator.Cpf(form.responsible_cpf).err === true) {
    isValid = false
  } else if (validator.Required(form.mother_name).err === true) {
    isValid = false
  } else if (validator.Phone(form.responsible_phone).err === true) {
    isValid = false
  } else if (validator.Required(form.name).err === true) {
    isValid = false
  } else if (form.cpf !== "" && validator.Cpf(form.cpf).err === true) {
    isValid = false
  } else if (validator.Required(form.email).err === true) {
    isValid = false
  } else if (validator.DataPcd(form.birth_date).err === true) {
    isValid = false
  } else if (validator.Required(form.sex).err === true) {
    isValid = false
  } else if (validator.Required(form.shirt_size).err === true) {
    isValid = false
  } else if (validator.Required(form.country).err === true) {
    isValid = false
  } else if (validator.Required(form.state).err === true) {
    isValid = false
  } else if (validator.Required(form.city).err === true) {
    isValid = false
  } else if (validator.Required(form.zip_code).err === true) {
    isValid = false
  } else if (validator.Required(form.district).err === true) {
    isValid = false
  } else if (validator.Required(form.street).err === true) {
    isValid = false
  }

  return isValid
}

export const filterMask = (form) => {
  const tempForm = form

  if (form.cpf) {
    tempForm.cpf = form.cpf.replace(/[^0-9]/g, "")
  }
  if (form.responsible_cpf) {
    tempForm.responsible_cpf = form.responsible_cpf.replace(/[^0-9]/g, "")
  }
  if (form.responsible_phone) {
    tempForm.responsible_phone = form.responsible_phone.replace(/[^0-9]/g, "")
  }
  if (form.zip_code) {
    tempForm.zip_code = form.zip_code.replace(/[^0-9]/g, "")
  }

  return tempForm
}
