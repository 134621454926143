import * as validator from "../../utils/validators"

export const formFields = { 
  name: "",
  cpf: "",
  phone: "55 ",
  birth_date: "",
  fishing_license: "",
  email: "",
  shirt_size: "",
  is_captain: true,
  sex: "",
  photo: "",
  team_name: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  district: "",
  street: "",
  number: "",
  complement: "",
}

export const validateForm = (form) => {
  let isValid = true
  
  if (validator.Required(form.name).err === true) {
    isValid = false
  } else if (validator.Required(form.email).err === true) {
    isValid = false
  } else if (validator.Required(form.fishing_license).err === true) {
    isValid = false
  } else if (form.cpf !== "" && validator.Cpf(form.cpf).err === true) {
    isValid = false
  } else if (validator.Phone(form.phone).err === true) {
    isValid = false
  } else if (validator.Date16(form.birth_date).err === true) {
    isValid = false
  } else if (validator.Required(form.sex).err === true) {
    isValid = false
  } else if (validator.Required(form.shirt_size).err === true) {
    isValid = false
  } else if (validator.Required(form.country).err === true) {
    isValid = false
  } else if (validator.Required(form.state).err === true) {
    isValid = false
  } else if (validator.Required(form.city).err === true) {
    isValid = false
  } else if (validator.Required(form.zip_code).err === true) {
    isValid = false
  } else if (validator.Required(form.district).err === true) {
    isValid = false
  } else if (validator.Required(form.street).err === true) {
    isValid = false
  }

  return isValid
}

export const filterMask = (form) => {
  const tempForm = form

  if (form.cpf) {
    tempForm.cpf = form.cpf.replace(/[^0-9]/g, "")
  }
  if (form.phone) {
    tempForm.phone = form.phone.replace(/[^0-9]/g, "")
  }
  if (form.zip_code) {
    tempForm.zip_code = form.zip_code.replace(/[^0-9]/g, "")
  }

  return tempForm
}