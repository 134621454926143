import MuiGrid from '@mui/material/Grid'

import * as Fields from './Fields'

function Form(props) {
  return (
    <MuiGrid container spacing={2} sx={{ marginBottom: '30px' }}>
      {props.data.map(element =>
        Fields[element.type]({
          field: element,
          isSubmit: props.isSubmit,
          value: props.value[element?.name] || '',
          onChange: props.onChange,
          type: props?.type || undefined
        })
      )}
    </MuiGrid>
  )
}

export default Form
