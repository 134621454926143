import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'

import { colors } from '../../core/stylesConfig'

const drawerWidth = 240

export const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: `${drawerWidth}px`
  })
}))

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between'
}))

export const Drawer = {
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    backgroundColor: colors.primary.main,
    width: drawerWidth,
    boxSizing: 'border-box'
  }
}

export const Logo = {
  display: 'flex',
  alignItems: 'center',
  padding: '15px'
}

export const ActivedButton = {
  bgcolor: colors.primary.dark,
  color: colors.primary.contrastText
}

export const InactivedButton = {
  color: colors.primary.contrastText
}

export const Container = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh'
}
