import { Box } from "@mui/system"

import React from "react"

// import InstagramIcon from "@mui/icons-material/Instagram"
// import FacebookIcon from "@mui/icons-material/Facebook"

import * as styles from "./styles"
import FooterImg from '../../assets/img/footer.jpg'

const Index = () => {
  return (
    <Box sx={styles.footerMain}>
      <img
        src={FooterImg}
        alt="footer"
      ></img>
    </Box>
  )
}

export default Index
