import { parse, differenceInYears } from "date-fns"

export const Cpf = value => {
  let resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')
  const aux = value.split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value: aux.join('')
    }
  } else {
    let d1 = 0
    for (let i = 0; i < 9; i++) {
      d1 += parseInt(aux[i]) * (10 - i)
    }
    d1 = d1 % 11
    if (d1 < 2) {
      d1 = 0
    } else {
      d1 = 11 - d1
    }
    let d2 = 0
    for (let i = 0; i < 10; i++) {
      d2 += parseInt(aux[i]) * (11 - i)
    }
    d2 = d2 % 11
    if (d2 < 2) {
      d2 = 0
    } else {
      d2 = 11 - d2
    }
    if (aux[9] !== d1.toString() || aux[10] !== d2.toString()) {
      resp = {
        err: true,
        msg: 'Cpf Inválido',
        value: aux.join('')
      }
    }
  }
  return resp
}

export const Phone = value => {
  let resp = { err: false, value }
  value = value.replace(/[^0-9]/g, '')
  if (value.split('').length < 3) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else {
    if (value.split('').length < 12) {
      resp = {
        err: true,
        msg: 'Número Inválido',
        value
      }
    }
  }
  return resp
}

export const Cep = value => {
  value = value.replace(/[^0-9]/g, '')
  let resp = { err: false, value }
  if (value.split('').length < 1) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else {
    if (value.split('').length < 8) {
      resp = {
        err: true,
        msg: 'Cep Inválido',
        value
      }
    }
  }
  return resp
}

export const Number = value => {
  let resp
  if (value.split.length > 0) {
    resp = {
      err: false,
      value
    }
  } else {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const Required = value => {
  let resp = { err: false, value }
  if (value === '') {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  }
  return resp
}

export const Opcional = value => {
  const resp = { err: false, value }
  return resp
}

export const ValidatorDate = value => {
  let resp = { err: false, value }
  const aux = value
    .toString()
    .replace(/[^0-9]/g, '')
    .split('')
  if (aux.length === 0) {
    resp = {
      err: true,
      msg: 'Campo Obrigatório',
      value
    }
  } else if (aux.length === 6) {
    resp = {
      err: true,
      msg: 'Formato esperado 01/01/2001',
      value
    }
  } else if (aux.length < 8) {
    resp = {
      err: true,
      msg: 'Formato de Data Inválido',
      value
    }
  }

  return resp
}

export const Date16 = value => {
  let resp = { err: false, value }
  let value1 = value.replace(/[^0-9]/g, "").split("")
  if (value1.length === 0) {
    resp = {
      err: true,
      msg: "Campo Obrigatório",
      value,
    }
  } else if (value1.length === 6) {
    resp = {
      err: true,
      msg: "Formato esperado 01/01/2001",
      value,
    }
  } else if (value1.length < 8) {
    resp = {
      err: true,
      msg: "Formato de Data Inválido",
      value,
    }
  } else {
    const data = parse(value, 'dd/MM/yyyy', new Date());
    
    const diferencaAnos = differenceInYears(new Date(2024, 6, 2), data);
    
    if (diferencaAnos < 16) {
      resp = {
        err: true,
        msg: "Fora da Faixa etária",
        value,
      }
    }
  }
  
  return resp
}

export const DateSenior = value => {
  let resp = { err: false, value }
  let value1 = value.replace(/[^0-9]/g, "").split("")
  if (value1.length === 0) {
    resp = {
      err: true,
      msg: "Campo Obrigatório",
      value,
    }
  } else if (value1.length === 6) {
    resp = {
      err: true,
      msg: "Formato esperado 01/01/2001",
      value,
    }
  } else if (value1.length < 8) {
    resp = {
      err: true,
      msg: "Formato de Data Inválido",
      value,
    }
  } else {
    const data = parse(value, 'dd/MM/yyyy', new Date())
    
    const diferencaAnos = differenceInYears(new Date(2024, 6, 2), data);
    
    if (diferencaAnos < 60) {
      resp = {
        err: true,
        msg: "Fora da Faixa etária",
        value,
      }
    }
  }
  
  return resp
}

export const DateInfantil = value => {
  let resp = { err: false, value }
  let value1 = value.replace(/[^0-9]/g, "").split("")
  if (value1.length === 0) {
    resp = {
      err: true,
      msg: "Campo Obrigatório",
      value,
    }
  } else if (value1.length === 6) {
    resp = {
      err: true,
      msg: "Formato esperado 01/01/2001",
      value,
    }
  } else if (value1.length < 8) {
    resp = {
      err: true,
      msg: "Formato de Data Inválido",
      value,
    }
  } else {
    const data = parse(value, 'dd/MM/yyyy', new Date());
    
    const diferencaAnos = differenceInYears(new Date(2024, 6, 2), data)
    
    if (diferencaAnos < 6) {
      resp = {
        err: true,
        msg: "Fora da Faixa etária",
        value,
      }
    } else if (diferencaAnos > 12) {
      resp = {
        err: true,
        msg: "Fora da Faixa etária",
        value,
      }
    }
  }
  
  return resp
}

export const DataPcd = (value) => {
  let resp = { err: false, value }
  let value1 = value.replace(/[^0-9]/g, "").split("")
  if (value1.length === 0) {
    resp = {
      err: true,
      msg: "Campo Obrigatório",
      value,
    }
  } else if (value1.length === 6) {
    resp = {
      err: true,
      msg: "Formato esperado 01/01/2001",
      value,
    }
  } else if (value1.length < 8) {
    resp = {
      err: true,
      msg: "Formato de Data Inválido",
      value,
    }
  } else {
    const data = parse(value, 'dd/MM/yyyy', new Date());
    
    const diferencaAnos = differenceInYears(new Date(2024, 6, 2), data)
    
    if (diferencaAnos < 6) {
      resp = {
        err: true,
        msg: "Fora da Faixa etária",
        value,
      }
    }
  }
  
  return resp
}
