import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Button, Container, Grid } from '@mui/material';
import { links } from '../../routes/data'

const TelaComBotoes = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Typography sx={{marginBottom: '50px'}} variant="h4" gutterBottom>
        Painéis
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.infantilPainel)}>
            Infanto Juvenil
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.seniorPainel)}>
            Senior
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.pcdPainel)}>
            PCD
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.caiaquePainel)}>
            Caiaque
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.canoaPainel)}>
            Canoa
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.motorizadaPainel)}>
            Motorizada
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate(links.esportes)}>
            Esporte
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TelaComBotoes;
