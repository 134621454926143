import { uniqueId } from 'lodash'
import { useNavigate } from 'react-router-dom'

import {
  GridActionsCellItem,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PaidIcon from '@mui/icons-material/Paid'
import VisibilityIcon from '@mui/icons-material/Visibility'

import * as mask from '../../utils/mask'
import { links } from '../../routes/data'

import * as styles from './styles'

function Table(props) {
  const navigate = useNavigate()

  function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(_event, value) => apiRef.current.setPage(value - 1)}
      />
    )
  }

  const renderColumn = element => ({
    field: element.name,
    filterable: element.filter,
    renderHeader: () => (
      <Typography sx={styles.TextHeader}>{element.label}</Typography>
    ),
    width: element.width,
    hide: element.hide,
    editable: false,
    renderCell: params =>
      element.name !== 'payment_id' ? (
        <Typography sx={styles.TextCell}>
          {element?.mask
            ? mask[element.mask](params.row[element.name])
            : params.row[element.name]}
        </Typography>
      ) : params.row[element.name] !== null &&
        params.row[element.name] !== 'Null' ? (
        <Box sx={styles.Icon}>
          <CheckCircleIcon color="success" />
        </Box>
      ) : (
        <Box sx={styles.Icon}>
          <CancelIcon color="error" />
        </Box>
      )
  })

  function createColumns() {
    const cols = []
    for (let index = 0; index < props.columns.length; index++) {
      const element = props.columns[index]
      cols.push(renderColumn(element))
    }

    const aux = [
      {
        field: 'actions',
        type: 'actions',
        renderHeader: () => (
          <Typography sx={styles.TextHeader}>Ações</Typography>
        ),
        width: 120,
        editable: false,
        renderCell: params => [
          <GridActionsCellItem
            key={uniqueId()}
            icon={<VisibilityIcon />}
            label="Mostrar"
            onClick={() => {
              props.setMethod('show')
              props.setFormData(params.row)
              props.setUuid(params.id)
            }}
          />,
          <GridActionsCellItem
            key={uniqueId()}
            icon={<EditIcon />}
            label="Editar"
            onClick={() => {
              props.setMethod('edit')
              props.setFormData(params.row)
              props.setUuid(params.id)
            }}
          />,
          <>
            {props.payament === true && <GridActionsCellItem
              key={uniqueId()}
              icon={<PaidIcon />}
              label="Pagar"
              onClick={() => {
                props.setMethod('payment')
                props.setFormData(params.row)
                props.setUuid(params.id)
              }}
            />}
          </>,
          <GridActionsCellItem
            key={uniqueId()}
            icon={<DeleteIcon />}
            label="Deletar"
            onClick={() => {
              props.setMethod('delete')
              props.setUuid(params.id)
            }}
          />
        ]
      },
      ...cols
    ]

    return aux
  }

  return (
    <>
      <Box sx={styles.Box}>
        <Typography variant="h4">{props.title}</Typography>
        <div>  
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ marginRight: '20px' }}
            onClick={() => navigate(links.home)}
          >
            Voltar
          </Button>
          
          {props.new === true && 
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={() => navigate(props.link)}
            >
              Novo
            </Button>
          } 
        </div>
      </Box>

      <styles.StripedDataGrid
        autoHeight
        density="compact"
        rows={props.rows}
        getRowId={row => row.uuid}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
        }
        columns={createColumns()}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
          Pagination: CustomPagination
        }}
      />
    </>
  )
}

export default Table
