import React from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import api from '../../services/apiPcd'
import { varLocalStorage } from '../../core/configs'
import * as Components from '../../components'
import * as Functions from '../../utils/functions'
import { links } from '../../routes/data'

import * as Data from './data'
import * as styles from './styles'

const PainelInfantil = () => {
  const [method, setMethod] = React.useState('table')
  const [uuid, setUuid] = React.useState('')
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [config, setConfig] = React.useState(null)

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [formData, setFormData] = React.useState([])
  const [editFormData, setEditFormData] = React.useState([])
  const [rows, setRows] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState(null)

  const theme = useTheme()
  const navigate = useNavigate()

  const handleChangeForm = (name, newValue) => {
    Functions.changeForm(formData, setFormData, name, newValue)
  }

  const configToken = () => {
    const authData = JSON.parse(localStorage.getItem(varLocalStorage))
    if (authData) {
      setConfig({
        headers: {
          Autentication: authData.token
        }
      })
    } else {
      setConfig({})
      navigate(links.login)
    }
  }

  const deleteData = async () => {
    setMethod('table')
    setTypeAlert('loading')

    try {
      await api.delete(`/pcd/${uuid}`, config)

      setMsg('Cadastro Deletado com Sucesso')
      setTypeAlert('success')
    } catch (err) {
      if (err?.response?.data?.detail[0]?.msg) {
        setMsg(err.response.data.detail[0].msg)
      } else {
        setMsg('Não foi possível concluir a Operação!')
      }
      setTypeAlert('error')
    }
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    let aux = []
    try {
      const resp = await api.get('/pcd/', { ...config, params: { all: true } })
      aux = resp.data
      setOpenAlert(false)
    } catch (err) {
      if (err?.message && err.message === 'Network Error') {
        setMsg(
          'Erro ao carregar os dados! Verifique a internet e tente novamente'
        )
      }
      setTypeAlert('error')
    }

    const temp = []
    for (let i = 0; i < aux.length; i++) {
      temp.push({
        ...aux[i],
        birth_date: Functions.maskDate(aux[i].birth_date)
      })
    }

    setRows(temp)
  }

  const handleSubmit = async () => {
    setMsg('')
    setIsSubmit(true)
    const validate = Functions.validateForm(Data.editForm, formData)

    if (validate === false) {
      const tempData = Functions.sendData(editFormData, formData)
      const tam = Object.keys(tempData).length
      if (tam > 0) {
        const data = Functions.formData(tempData)

        setTypeAlert('loading')
        setOpenAlert(true)
        try {
          await api.put(`/pcd/${uuid}`, data, config)
          setMsg('Cadastro Atualizdo com Sucesso!')
          setTypeAlert('success')
        } catch (err) {
          if (err?.response?.data?.detail[0]?.msg) {
            setMsg(err.response.data.detail[0].msg)
          } else {
            setMsg('Não foi possível concluir a Operação!')
          }
          setTypeAlert('error')
        }
      }
    }
  }

  const loadAdress = async cep => {
    try {
      const resp = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (resp.data?.cep) {
        setFormData({
          ...formData,
          state: resp.data.uf,
          city: resp.data.localidade,
          district: resp.data.bairro,
          street: resp.data.logradouro,
          complement: resp.data.complemento
        })
      }
    } catch (err) {}
  }

  React.useEffect(() => {
    if (
      formData?.zip_code &&
      formData.zip_code.replace(/[^0-9]/g, '').length === 8
    ) {
      loadAdress(formData.zip_code.replace(/[^0-9]/g, ''))
    }
  }, [formData && formData?.zip_code])

  React.useEffect(() => {
    if (method === 'table') {
      setIsSubmit(false)
      setFormData([])
      setSelectedRow(null)
    } else if (method === 'delete') {
      setMsg('Essa operação é Irreversível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    }
  }, [method])

  React.useEffect(() => {
    configToken()
    setMsg('')
    setIsSubmit(false)
  }, [])

  React.useEffect(() => {
    if (rows.length === 0 && config !== null) {
      loadData()
    }
  }, [config])

  React.useEffect(() => {
    if (selectedRow) {
      const aux = Functions.filterData(Data.editForm, selectedRow)
      setEditFormData(aux)
      setFormData(aux)
    }
  }, [selectedRow])

  return (
    <>
      <Components.Header title="PCD" />
      <Box sx={styles.Container}>
        <CssBaseline />
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Total:
            </Typography>
            <Typography
              variant="h4"
              mb={2}
              ml={2}
              color={theme.palette.primary.main}
            >
              {rows.length} inscritos
            </Typography>
          </Box>
        </Box>
        {method === 'show' ? (
          <>
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Visualizar Pescador
            </Typography>

            <Components.Form
              data={Data.showData}
              isSubmit={isSubmit}
              value={formData}
              onChange={handleChangeForm}
            />
          </>
        ) : method === 'edit' ? (
          <>
            <Typography variant="h4" mb={2} color={theme.palette.primary.main}>
              Editar Pescador
            </Typography>

            <Components.Form
              data={Data.editForm}
              isSubmit={isSubmit}
              value={formData}
              onChange={handleChangeForm}
            />
          </>
        ) : (
          <Components.Table
            title={Data.localTable.title}
            rows={rows}
            columns={Data.localTable.columns}
            setMethod={setMethod}
            setFormData={setSelectedRow}
            setUuid={setUuid}
            link={links.pcd}
            new={true}
          />
        )}

        {method !== 'table' && method !== 'delete' && (
          <Stack spacing={5} direction="row-reverse">
            {method === 'show' && (
              <Button
                size="large"
                variant="contained"
                onClick={() => setMethod('edit')}
              >
                Editar
              </Button>
            )}
            <Button
              size="large"
              variant={method === 'show' ? 'outlined' : 'contained'}
              onClick={() =>
                method === 'show' ? setMethod('table') : handleSubmit()
              }
            >
              {method === 'show' ? 'Voltar' : 'Confirmar'}
            </Button>
            {method !== 'show' && (
              <Button
                size="large"
                variant="outlined"
                onClick={() => setMethod('table')}
              >
                Cancelar
              </Button>
            )}
          </Stack>
        )}
        <Box mb={2} />

        <Components.Alert
          open={openAlert}
          setOpen={setOpenAlert}
          alert={typeAlert}
          msg={msg}
          delete={deleteData}
        />
      </Box>
      <Components.Footer />
    </>
  )
}

export default PainelInfantil
