import React from 'react'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import { msg as message } from '../../core/messages'
import api from '../../services/api'
import { varLocalStorage } from '../../core/configs'
import * as Components from '../../components'
import * as validators from '../../utils/validators'
import * as mask from '../../utils/mask'

const Modality = props => {
  const [value, setValue] = React.useState('')
  const [editValue, setEditValue] = React.useState('')
  const [price, setPrice] = React.useState('')
  const [editPrice, setEditPrice] = React.useState('')
  const [edit, setEdit] = React.useState(false)
  const [selectedData, setSelectedData] = React.useState({})
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [all, setAll] = React.useState(false)

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [data, setData] = React.useState([])
  const [config, setConfig] = React.useState(null)

  const configToken = () => {
    const authData = JSON.parse(localStorage.getItem(varLocalStorage))
    if (authData) {
      setConfig({
        headers: {
          Autentication: authData.token
        }
      })
    } else {
      window.location.href = process.env.REACT_APP_API_URL_PAINEL + '/'
    }
  }

  const filtered = () => {
    let test = false

    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === true) {
        setSelectedData(data[i])
        setValue(data[i].name)
        setEditValue(data[i].name)
        setPrice(data[i].price)
        setEditPrice(data[i].price)
        test = true
        break
      }
    }

    if (test === true) {
      setEdit(true)
    }
  }

  const handleChange = event => {
    if (event.target.checked === false) {
      setAll(false)
    }

    const aux = []
    for (let i = 0; i < data.length; i++) {
      if (data[i].name !== event.target.name) {
        aux.push(data[i])
      } else {
        aux.push({
          uuid: data[i].uuid,
          name: data[i].name,
          price: data[i].price,
          checked: event.target.checked
        })
      }
    }

    let val = true

    for (let i = 0; i < aux.length; i++) {
      if (aux[i].checked === false) {
        val = false
        break
      }
    }
    if (val === true) {
      setAll(true)
    }

    setData(aux)
  }

  const markAll = checked => {
    const aux = []

    for (let i = 0; i < data.length; i++) {
      aux.push({
        uuid: data[i].uuid,
        name: data[i].name,
        checked
      })
    }

    setData(aux)
  }

  const deleteData = async () => {
    setMsg('')
    setTypeAlert('loading')
    setOpenAlert(true)
    let error = false

    for (let i = 0; i < data.length; i++) {
      if (data[i].checked === true) {
        try {
          await api.delete(`/modality/${data[i].uuid}`, config)
        } catch (err) {
          console.log(err)
          error = true
        }
      }
    }

    if (error === false) {
      setMsg('Modalidade(s) Deletada(s) com Sucesso!')
      setTypeAlert('success')
    } else {
      setMsg('Ocorreu um erro, Tente Novamente mais Tarde!')
      setTypeAlert('error')
    }
  }

  const handleDelete = () => {
    if (edit === true) {
      setSelectedData({})
      setValue('')
      setPrice('')
      setEdit(false)
    } else {
      setMsg('Essa operação é Irreversível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    }
  }

  const handleSubmit = async () => {
    setIsSubmit(true)
    const validate = validators.Required(value).err
    const validateP = validators.Required(price).err

    if (validate === false && validateP === false) {
      setTypeAlert('loading')
      setOpenAlert(true)

      if (edit === true) {
        if (editValue === value) {
          setMsg(message.error)
          setTypeAlert('error')
        } else {
          let data = {}
          if (editValue !== value) {
            data = {
              ...data,
              name: value
            }
          }
          if (editPrice !== price) {
            data = {
              ...data,
              price
            }
          }

          try {
            await api.put(`/modality/${selectedData.uuid}`, data, config)
            setMsg(message.success)
            setIsSubmit(false)
            setTypeAlert('success')
          } catch (err) {
            console.log(err)
            setMsg(message.error)
            setTypeAlert('error')
          }
        }
      } else {
        try {
          await api.post(
            '/modality/',
            {
              name: value,
              price
            },
            config
          )
          setMsg(message.success)
          setIsSubmit(false)
          setTypeAlert('success')
        } catch (err) {
          console.log(err)
          setMsg(message.error)
          setTypeAlert('error')
        }
      }
    }
  }

  const loadData = async () => {
    setTypeAlert('loading')
    setOpenAlert(true)

    try {
      const resp = await api.get('/modality/', {
        ...config,
        params: { all: true }
      })

      if (resp.data.length > 0) {
        const aux = []
        for (let i = 0; i < resp.data.length; i++) {
          aux.push({
            ...resp.data[i],
            checked: false
          })

          const order = aux.sort((a, b) => {
            return a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : 0
          })

          setData(order)
        }
      }

      setOpenAlert(false)
    } catch (err) {
      console.log(err)
      setMsg(message.error)
      setTypeAlert('error')
      setOpenAlert(true)
    }
  }

  React.useEffect(() => {
    if (all === true) {
      markAll(true)
    } else {
      let control = true
      for (let i = 0; i < data.length; i++) {
        if (data[i].checked === false) {
          control = false
          break
        }
      }

      if (control === true) {
        markAll(false)
      }
    }
  }, [all])

  React.useEffect(() => {
    configToken()
    loadData()
  }, [])

  return (
    <>
      <Stack spacing={2} direction="row">
        <TextField
          fullWidth
          type="text"
          variant="outlined"
          label="Categoria"
          error={isSubmit && validators.Required(value).err}
          helperText={isSubmit && validators.Required(value).msg}
          value={mask.Text(value)}
          onChange={e => {
            setValue(validators.Required(e.target.value).value)
          }}
        />
        <TextField
          fullWidth
          type="text"
          variant="outlined"
          label="Preço"
          error={isSubmit && validators.Required(price).err}
          helperText={isSubmit && validators.Required(price).msg}
          value={mask.Moeda(price)}
          onChange={e => {
            setPrice(validators.Required(e.target.value).value)
          }}
        />
        <Button size="large" variant="contained" onClick={handleSubmit}>
          {edit === true ? 'Salvar' : 'Criar'}
        </Button>
        <Button size="large" variant="contained" onClick={handleDelete}>
          {edit === true ? 'Cancelar' : 'Excluir'}
        </Button>
        {edit === false && (
          <Button size="large" variant="contained" onClick={filtered}>
            Editar
          </Button>
        )}
        <Button
          size="large"
          variant="outlined"
          onClick={() => props.setMethod('table')}
        >
          Início
        </Button>
      </Stack>
      <Divider sx={{ margin: '30px 0px' }} />
      <Grid container spacing={2} sx={{ marginBottom: '30px' }}>
        <Grid item>
          <FormControl>
            <FormGroup>
              {data.length > 0 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={all}
                      onChange={e => setAll(e.target.checked)}
                      name={'category'}
                    />
                  }
                  label={'Todas as Categorias'}
                />
              )}
              {data.map((element, index) => (
                <FormControlLabel
                  key={element.uuid}
                  control={
                    <Checkbox
                      checked={data[index].checked || false}
                      onChange={e => handleChange(e)}
                      name={element.name}
                    />
                  }
                  label={element.name + ' - ' + mask.Moeda(element.price)}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Components.Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={deleteData}
      />
    </>
  )
}

export default Modality
