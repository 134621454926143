import axios from 'axios'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_MOTORIZADA}`,
  timeout: 15000
})

// api.interceptors.response.use(
//   response => response,
//   error => {
//     return Promise.reject(msgError(error))
//   }
// )

// const msgError = error => {
//   if (axios.isAxiosError(error)) {
//     // Filtrando o erro para verificar se é um AxiosError
//     const axiosError = error
//     if (axiosError.response) {
//       // O erro possui uma resposta do servidor
//       if (typeof axiosError.response.data.detail === 'string') {
//         return { message: axiosError.response.data.detail }
//       } else if (axiosError.response.data.detail[0].message) {
//         return { message: axiosError.response.data.detail[0].message }
//       } else {
//         return { message: 'Sem resposta do servidor!' }
//       }
//     } else if (axiosError.request) {
//       // A requisição foi feita, mas não houve resposta do servidor
//       return { message: 'Sem resposta do servidor!' }
//     } else {
//       // Outros tipos de erro
//       return { message: axiosError.message }
//     }
//   } else {
//     // Outros tipos de erro que não são AxiosError
//     return { message: 'Ocorreu um erro!' }
//   }
// }

export default api
