import headerBack from '../../assets/img/Colored.png'

export const headerMain = {
  width: '100%',
  maxWidth: '1920px',
  height: '150px',
  backgroundImage: `url(${headerBack})`,
  backgroundPosition: 'center',
  boxShadow: '0px 7px 19px 0px rgba(0,0,0,0.75)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const textBack = {
  color: '#fff',
  background: ' rgba(0, 0, 0, 0.4)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(2px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '150px',
  justifyContent: 'center',
  minWidth: '360px',
  width: '100%',
  maxWidth: '420px'
}
