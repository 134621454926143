import { uniqueId } from 'lodash'

export const columns = [
  {
    key: uniqueId(),
    label: 'Uuid',
    name: 'uuid',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'name',
    width: 200,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Telefone',
    name: 'phone',
    width: 200,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Email',
    name: 'email',
    width: 200,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'CPF',
    name: 'cpf',
    width: 150,
    filter: true,
    hide: false,
    mask: 'Cpf'
  },
  {
    key: uniqueId(),
    label: 'Data de Nascimento',
    name: 'birth_date',
    width: 170,
    hide: false,
    mask: 'DateMask'
  },
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    width: 180,
    filter: true,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Sexo',
    name: 'sex',
    width: 50,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'Camisa',
    name: 'shirt_size',
    width: 80,
    hide: false
  },
  {
    key: uniqueId(),
    label: 'País',
    name: 'country',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Cidade',
    name: 'city',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'CEP',
    name: 'zip_code',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    width: 200,
    hide: true
  },
  {
    key: uniqueId(),
    label: 'Complemento',
    name: 'complement',
    width: 200,
    hide: true
  }
]

export const showData = [
  {
    key: uniqueId(),
    label: 'Foto do Participante',
    name: 'photo',
    type: 'ShowImg',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 2,
      sm: 2
    }
  },
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'name',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Telefone',
    name: 'phone',
    type: 'TextShow',
    mask: 'Phone',
    validator: 'Phone',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Email',
    name: 'email',
    type: 'TextShow',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'CPF',
    name: 'cpf',
    type: 'TextShow',
    mask: 'Cpf',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Data de Nascimento',
    name: 'birth_date',
    type: 'TextShow',
    mask: 'DateMask',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Tamanho da Camisa',
    name: 'shirt_size',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Sexo',
    name: 'sex',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'País',
    name: 'country',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Cidade',
    name: 'city',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'CEP',
    name: 'zip_code',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  },
  {
    key: uniqueId(),
    label: 'Complemento',
    name: 'complement',
    type: 'TextShow',
    mask: 'Text',
    measures: {
      xs: 12,
      sm: 12
    }
  }
]

export const editForm = [
  {
    key: uniqueId(),
    label: 'Foto do Participante',
    name: 'photo',
    type: 'FileImg',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 4,
      sm: 2
    }
  },
  {
    key: uniqueId(),
    label: 'Número de Inscrição',
    name: 'subscription_number',
    type: 'TextShow',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 8,
      sm: 10
    }
  },
  {
    key: uniqueId(),
    label: 'Nome',
    name: 'name',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 8
    }
  },
  {
    key: uniqueId(),
    label: 'Telefone',
    name: 'phone',
    type: 'TextField',
    mask: 'Phone',
    validator: 'Phone',
    max: 19,
    measures: {
      xs: 12,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Email',
    name: 'email',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 8
    }
  },
  {
    key: uniqueId(),
    label: 'CPF',
    name: 'cpf',
    type: 'TextField',
    mask: 'Cpf',
    validator: 'Cpf',
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Data de Nascimento',
    name: 'birth_date',
    type: 'TextField',
    mask: 'DateMask',
    validator: 'DateSenior',
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Tamanho da Camisa',
    name: 'shirt_size',
    type: 'Select',
    mask: 'Text',
    validator: 'Required',
    data: [
      {
        uuid: 'PP',
        name: 'PP'
      },
      {
        uuid: 'P',
        name: 'P'
      },
      {
        uuid: 'M',
        name: 'M'
      },
      {
        uuid: 'G',
        name: 'G'
      },
      {
        uuid: 'GG',
        name: 'GG'
      }
    ],
    measures: {
      xs: 3,
      sm: 2
    }
  },
  {
    key: uniqueId(),
    label: 'Sexo',
    name: 'sex',
    type: 'Select',
    mask: 'Text',
    validator: 'Required',
    data: [
      {
        uuid: 'F',
        name: 'Feminino'
      },
      {
        uuid: 'M',
        name: 'Masculino'
      }
    ],
    measures: {
      xs: 3,
      sm: 2
    }
  },
  {
    key: uniqueId(),
    label: 'País',
    name: 'country',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'CEP',
    name: 'zip_code',
    type: 'TextField',
    mask: 'Cep',
    validator: 'Cep',
    max: 10,
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Estado',
    name: 'state',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    max: 2,
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Cidade',
    name: 'city',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Bairro',
    name: 'district',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 6,
      sm: 4
    }
  },
  {
    key: uniqueId(),
    label: 'Rua',
    name: 'street',
    type: 'TextField',
    mask: 'Text',
    validator: 'Required',
    measures: {
      xs: 12,
      sm: 7
    }
  },
  {
    key: uniqueId(),
    label: 'Número',
    name: 'number',
    type: 'TextField',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 4,
      sm: 1
    }
  },
  {
    key: uniqueId(),
    label: 'Complemento',
    name: 'complement',
    type: 'TextField',
    mask: 'Text',
    validator: 'Opcional',
    measures: {
      xs: 8,
      sm: 12
    }
  }
]

export const localTable = {
  title: 'Pesca Sênior',
  baseUrl: '/contacts',
  columns
}
