// import background from "../../assets/img/background.png"

export const mainBox = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // backgroundImage: `url(${background})`,
  minHeight: "100vh",
  gap: "20px",
}

export const alertBox = {
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // backgroundImage: `url(${background})`,
}

export const ErrorModal = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }


export const imputGroup = {
  padding: "20px",
  backgroundColor: "#fff",
  borderRadius: "15px",
  minWidth: "350px",
  width: "100%",
  maxWidth: "800px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  boxShadow: "0px 7px 19px 0px rgba(0,0,0,0.75)",
  gap: "10px",
  marginTop: "10px",
}

export const imputBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  backgroundColor: "#fff",
  borderRadius: "15px",
  boxShadow: "0px 7px 19px 0px rgba(0,0,0,0.75)",
}

export const imageBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  minHeight: "50px",
  gap: "10px",
}

export const halfField = {
  width: "48%",
}

export const imageFormat = {
  maxWidth: "150px",
  maxHeight: "150px",
  minWidth: "100px",
  minHeight: "100px",
  objectFit: "cover",
  borderRadius: "50%",
}

export const field = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

export const session = {
  minWidth: "320px",
  width: "100%",
  maxWidth: "450px",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
  borderBottom: "2px dashed rgba(0, 0, 0, 0.32)",
}

export const texts = {
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
}

export const format = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}
