import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TimelapseIcon from '@mui/icons-material/Timelapse'

import * as styles from './styles'

function Alert(props) {
  function customIcon() {
    if (props.alert === 'error' || props.alert === 'delete')
      return <CancelIcon sx={{ fontSize: 100 }} color="error" />
    else if (props.alert === 'success')
      return <CheckCircleIcon sx={{ fontSize: 100 }} color="success" />
    else if (props.alert === 'loading')
      return <TimelapseIcon sx={{ fontSize: 100 }} color="primary" />
  }

  return (
    <Dialog open={props.open} aria-labelledby="responsive-dialog-title">
      <Box sx={styles.Dialog}>
        {customIcon()}
        <Typography variant="h5">{props.msg}</Typography>
        {props.alert !== 'loading' && (
          <Stack spacing={3} direction="row">
            {props.alert === 'delete' && (
              <Button
                onClick={() => props.setOpen(false)}
                size="large"
                variant="outlined"
              >
                Cancelar
              </Button>
            )}
            <Button
              onClick={() =>
                props.alert === 'delete'
                  ? props.delete()
                  : props.alert === 'success'
                  ? document.location.reload()
                  : props.setOpen(false)
              }
              size="large"
              variant="contained"
            >
              Confirmar
            </Button>
          </Stack>
        )}
      </Box>
    </Dialog>
  )
}

export default Alert
