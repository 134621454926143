import React from 'react'
import { uniqueId } from 'lodash'

import MuiBox from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import MuiCheckbox from '@mui/material/Checkbox'
import MuiFormControl from '@mui/material/FormControl'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import MuiFormGroup from '@mui/material/FormGroup'
import MuiFormHelperText from '@mui/material/FormHelperText'
import MuiFormLabel from '@mui/material/FormLabel'
import MuiGrid from '@mui/material/Grid'
import MuiInputLabel from '@mui/material/InputLabel'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiRadio from '@mui/material/Radio'
import MuiRadioGroup from '@mui/material/RadioGroup'
import MuiSelect from '@mui/material/Select'
import MuiTextField from '@mui/material/TextField'
import MuiTypography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useTheme } from '@mui/material'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PaidIcon from '@mui/icons-material/Paid'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import * as validators from '../../utils/validators'
import * as mask from '../../utils/mask'

export function ShowImg(props) {
  const theme = useTheme()

  const generateUrl = value => {
    return `${process.env.REACT_APP_API_URL_BACKEND}/file/${value}/?t=${value}`
  }

  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      {props.value ? (
        <MuiBox
          component="img"
          sx={{ height: 108, width: 108 }}
          alt="Imagem de Perfil"
          src={generateUrl(props.value)}
        />
      ) : (
        <AccountCircleIcon
          sx={{
            fontSize: 108,
            color: theme.palette.primary.main,
            alignSelf: 'center',
            justifySelf: 'center'
          }}
        />
      )}
    </MuiGrid>
  )
}

export function FileImg(props) {
  const theme = useTheme()

  const generateUrl = value => {
    return `${process.env.REACT_APP_API_URL_BACKEND}/file/${value}/?t=${value}`
  }

  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <label htmlFor="upload-photo">
        <input
          style={{ display: 'none' }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={e =>
            props.onChange(props.field.name, e.currentTarget.files[0])
          }
        />

        <MuiBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {props.value ? (
            <MuiBox
              component="img"
              sx={{ height: 108, width: 108 }}
              alt="Imagem de Perfil"
              src={
                typeof props.value === 'string'
                  ? generateUrl(props.value)
                  : URL.createObjectURL(props.value)
              }
            />
          ) : props?.type ? (
            <PaidIcon
              sx={{
                fontSize: 108,
                color: theme.palette.primary.main,
                alignSelf: 'center',
                justifySelf: 'center'
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                fontSize: 108,
                color: theme.palette.primary.main,
                alignSelf: 'center',
                justifySelf: 'center'
              }}
            />
          )}
          <MuiButton variant="contained" size="large" component="span">
            Carregar Foto
          </MuiButton>
        </MuiBox>
      </label>
    </MuiGrid>
  )
}

export function TextShow(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiBox
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <MuiTypography variant="h5" sx={{ marginRight: '5px' }}>
          {props.field.label}:
        </MuiTypography>
        <MuiTypography variant="h6">
          {mask[props.field.mask](props.value)}
        </MuiTypography>
      </MuiBox>
    </MuiGrid>
  )
}

export function Boolean(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiBox
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}
      >
        <MuiTypography variant="h5" sx={{ marginRight: '5px' }}>
          {props.field.label}:
        </MuiTypography>
        <MuiTypography variant="h6">
          {props.value === true ? 'Sim' : 'Não'}
        </MuiTypography>
      </MuiBox>
    </MuiGrid>
  )
}

export function Question(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <MuiTypography variant="h5" sx={{ marginRight: '5px' }}>
          {props.field.label}:
        </MuiTypography>
        <MuiTextField
          fullWidth
          type="text"
          variant="outlined"
          sx={{ display: 'flex', textAlign: 'center' }}
          error={
            props.isSubmit && validators[props.field.validator](props.value).err
          }
          helperText={
            props.isSubmit && validators[props.field.validator](props.value).msg
          }
          value={mask[props.field.mask](props.value)}
          onChange={e => {
            props.onChange(
              props.field.name,
              validators[props.field.validator](e.target.value).value
            )
          }}
        />
      </MuiBox>
    </MuiGrid>
  )
}

export function TextField(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiTextField
        fullWidth
        type="text"
        variant="outlined"
        label={props.field.label}
        sx={{ display: 'flex', textAlign: 'center' }}
        error={
          props.isSubmit && validators[props.field.validator](props.value).err
        }
        helperText={
          props.isSubmit && validators[props.field.validator](props.value).msg
        }
        value={mask[props.field.mask](props.value)}
        onChange={e => {
          props.onChange(
            props.field.name,
            validators[props.field.validator](e.target.value).value
          )
        }}
        inputProps={{ maxLength: props.field?.max || 255 }}
      />
    </MuiGrid>
  )
}

export function Password(props) {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiTextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        label={props.field.label}
        error={
          props.isSubmit && validators[props.field.validator](props.value).err
        }
        helperText={
          props.isSubmit && validators[props.field.validator](props.value).msg
        }
        value={mask[props.field.mask](props.value)}
        onChange={e => {
          props.onChange(
            props.field.name,
            validators[props.field.validator](e.target.value).value
          )
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </MuiGrid>
  )
}

export function Select(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormControl
        fullWidth
        error={
          props.isSubmit && validators[props.field.validator](props.value).err
        }
      >
        <MuiInputLabel>{props.field.label}</MuiInputLabel>
        <MuiSelect
          label={props.field.label}
          value={props.value}
          onChange={e => props.onChange(props.field.name, e.target.value)}
        >
          <MuiMenuItem key={uniqueId()} value={''}>
            -----
          </MuiMenuItem>
          {props.field?.data &&
            props.field?.data.map(element => (
              <MuiMenuItem key={element.uuid} value={element.uuid}>
                {element.name}
              </MuiMenuItem>
            ))}
        </MuiSelect>
        <MuiFormHelperText>
          {props.isSubmit && validators[props.field.validator](props.value).msg}
        </MuiFormHelperText>
      </MuiFormControl>
    </MuiGrid>
  )
}

export function RadioButton(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormControl>
        <MuiFormLabel>
          <MuiTypography variant="h4">{props.field.label}</MuiTypography>
        </MuiFormLabel>
        <MuiRadioGroup
          value={props.value}
          onChange={e => props.onChange(props.field.name, e.target.value)}
        >
          {props.field?.data &&
            props.field?.data.map(element => (
              <MuiFormControlLabel
                key={element.uuid}
                value={element.uuid}
                control={<MuiRadio />}
                label={element.name}
              />
            ))}
        </MuiRadioGroup>
      </MuiFormControl>
    </MuiGrid>
  )
}

export function CheckBox(props) {
  const [state, setState] = React.useState({})

  const handleChange = event => {
    const aux = {
      ...state,
      [event.target.name]: event.target.checked
    }
    return aux
  }

  React.useEffect(() => {
    if (props.value) {
      setState(props.value)
    }
  }, [props.value])

  React.useEffect(() => {
    if (props.field.data) {
      let aux = {}
      for (let i = 0; i < props.field.data.length; i++) {
        aux = {
          ...aux,
          [props.field.data[i].uuid]: false
        }
      }
      setState(aux)
    }
  }, [])

  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormControl>
        <MuiFormLabel>
          <MuiTypography variant="h4">{props.field.label}</MuiTypography>
        </MuiFormLabel>
        <MuiFormGroup>
          {props.field?.data &&
            props.field?.data.map(element => (
              <MuiFormControlLabel
                key={element.uuid}
                control={
                  <MuiCheckbox
                    checked={state[element.uuid] || false}
                    onChange={e => {
                      const value = handleChange(e)
                      props.onChange(props.field.name, value)
                    }}
                    name={element.uuid}
                  />
                }
                label={element.name}
              />
            ))}
        </MuiFormGroup>
      </MuiFormControl>
    </MuiGrid>
  )
}

export function BooleanBox(props) {
  return (
    <MuiGrid
      key={props.field.key}
      item
      xs={props.field.measures?.xs || undefined}
      sm={props.field.measures?.sm || undefined}
      md={props.field.measures?.md || undefined}
      lg={props.field.measures?.lg || undefined}
      xl={props.field.measures?.xl || undefined}
    >
      <MuiFormGroup>
        <MuiFormControlLabel
          control={
            <MuiCheckbox
              checked={
                typeof props.value === 'boolean'
                  ? props.value
                  : props.value === 'true'
              }
              onChange={e => {
                props.onChange(props.field.name, e.target.checked)
              }}
              name={props.field.name}
            />
          }
          label={props.field.label}
        />
      </MuiFormGroup>
    </MuiGrid>
  )
}
