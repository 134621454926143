import { grey } from '@mui/material/colors'

export const colors = {
  common: {
    black: '#000000',
    white: '#ffffff'
  },
  primary: {
    main: grey[700],
    light: grey[400],
    dark: grey[800],
    contrastText: '#ffffff'
  },
  // secondary: {
  //   main: amber[700],
  //   light: amber[400],
  //   dark: amber[800],
  //   contrastText: '#ffffff'
  // },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#f5f5f5',
    A200: '#efefef',
    A400: '#bdbdbd',
    A700: '#616161'
  },
  background: {
    default: '#ffffff'
  }
}
