import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { Add, Delete } from "@mui/icons-material"

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ptBR } from 'date-fns/locale'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentCopy from "@mui/icons-material/ContentCopy"

import avatar from "../../assets/img/avatar.jpg"
import peixe from "../../assets/img/peixe.png"
import warning from "../../assets/img/Warning.png"

import axios from "axios"
import QRCode from "react-qr-code"
import React, { useEffect, useRef, useState } from "react"
import { format, parse, isValid } from 'date-fns'

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import api from "../../services/apiMotorizada"

import * as mask from "../../utils/mask"
import * as validator from "../../utils/validators"

import * as styles from "./styles"
import { filterMask, formFields, validateForm } from "./data"

const Motorizada = () => {
  const [form, setForm] = useState(formFields)
  const [newImage, setNewImage] = useState(null)
  const [newImage2, setNewImage2] = useState(null)
  const [newImage3, setNewImage3] = useState(null)
  const [newImage4, setNewImage4] = useState(null)
  const [submit, setSubmit] = useState(false)
  const [tempToken, setTempToken] = useState("")

  const [member3, setMember3] = useState(false)
  const [member4, setMember4] = useState(false)
  
  const [loading, setLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState({ status: "Error", msg: "", open: false })
  const [modalOpen, setModalOpen] = useState(false)
  const [controlModal, setControlModal] = useState(0)
  const [code, setCode] = useState("MO-")

  const [isButtonEnabled, setIsButtonEnabled] = useState(true)
  const [timeLeft, setTimeLeft] = useState(0)

  const timeoutRef = useRef(null)
  const intervalRef = useRef(null)

  const MODALITY_VALUE = "350,00"
  const PIX_TYPE = "Chave"
  const PIX_KEY = "98f1bdf5-0fed-4641-99ad-4df4bfb1e1dc"
  const TEL = "(65) 9361-4982"

  const getCep = async (value) => {
    const tempCep = value.replace(/[^0-9]/g, "")
    if (tempCep.length === 8) {
      try {
        const url = `https://viacep.com.br/ws/${tempCep}/json/`
        const response = await axios.get(url)
        if (response.data.uf !== undefined) {
          setForm({ 
            ...form,
            state: response.data.uf,
            district: response.data.bairro,
            city: response.data.localidade,
            street: response.data.logradouro,
            complement: response.data.complemento
          })
        } else {
          setErrorStatus({ status: "error", msg: "CEP não foi Encontrado!", open: true })
        }
      } catch (error) {
        setErrorStatus({ status: "error", msg: "Problema de Conexão! Verifique se há Internet", open: true })
      }
    }
  }

  const filterDisabledButton = (type) => {
    if (type === 'delete') {
      if (member3 === false && member4 === false) {
        return true
      } else {
        return false
      }
    } else {
      if (type === 'add') {
        if (member3 === true && member4 === true) {
          return true
        } else {
          return false
        }
      }  
    }
  }

  const addMember = () => {
    if (member3 === false && member4 === false) {
      setMember3(true)
    } else if (member3 === true && member4 === false) {
      setMember4(true)
    }
  }

  const deleteMember = () => {
    if (member3 === true && member4 === true) {
      setMember4(false)
    } else if (member3 === true && member4 === false) {
      setMember3(false)
    }
  }

  const parseDate = (dateString) => {
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date())
    return isValid(parsedDate) ? parsedDate : null
  }

  const formatDate = (date) => {
    return date ? format(date, 'yyyy-MM-dd') : ''
  }

  const handleCLoseAlert = () => {
    setErrorStatus({ status: "error", msg: "", open: false })
  }

  const onChange = (key, value) => {
    if (key === 'is_captain_1' && value === 'true') {
      setForm({ ...form, [key]: value, is_captain_2: false, is_captain_3: false, is_captain_4: false })
    } else if (key === 'is_captain_1' && value === 'false') {
      setForm({ ...form, [key]: value, is_captain_2: true, is_captain_3: false, is_captain_4: false })
    } else if (key === 'is_captain_2' && value === 'true') {
      setForm({ ...form, [key]: value, is_captain_1: false, is_captain_3: false, is_captain_4: false })
    } else if (key === 'is_captain_2' && value === 'false') {
      setForm({ ...form, [key]: value, is_captain_1: true, is_captain_3: false, is_captain_4: false })
    } else if (member3 === true && key === 'is_captain_3' && value === 'true') {
      setForm({ ...form, [key]: value, is_captain_1: false, is_captain_2: false, is_captain_4: false })
    } else if (member3 === true && key === 'is_captain_3' && value === 'false') {
      setForm({ ...form, [key]: value, is_captain_1: true, is_captain_2: false, is_captain_4: false })
    } else if (member4 === true && key === 'is_captain_4' && value === 'true') {
      setForm({ ...form, [key]: value, is_captain_1: false, is_captain_2: false, is_captain_3: false })
    } else if (member4 === true && key === 'is_captain_4' && value === 'false') {
      setForm({ ...form, [key]: value, is_captain_1: true, is_captain_2: false, is_captain_3: false })
    } else if (key === 'is_boat_pilot_1' && value === 'true') {
      setForm({ ...form, [key]: value, is_boat_pilot_2: false, is_boat_pilot_3: false, is_boat_pilot_4: false })
    } else if (key === 'is_boat_pilot_1' && value === 'false') {
      setForm({ ...form, [key]: value, is_boat_pilot_2: true, is_boat_pilot_3: false, is_boat_pilot_4: false })
    } else if (key === 'is_boat_pilot_2' && value === 'true') {
      setForm({ ...form, [key]: value, is_boat_pilot_1: false, is_boat_pilot_3: false, is_boat_pilot_4: false })
    } else if (key === 'is_boat_pilot_2' && value === 'false') {
      setForm({ ...form, [key]: value, is_boat_pilot_1: true, is_boat_pilot_3: false, is_boat_pilot_4: false })
    } else if (member3 === true && key === 'is_boat_pilot_3' && value === 'true') {
      setForm({ ...form, [key]: value, is_boat_pilot_1: false, is_boat_pilot_2: false, is_boat_pilot_4: false })
    } else if (member3 === true && key === 'is_boat_pilot_3' && value === 'false') {
      setForm({ ...form, [key]: value, is_boat_pilot_1: true, is_boat_pilot_2: false, is_boat_pilot_4: false })
    } else if (member4 === true && key === 'is_boat_pilot_4' && value === 'true') {
      setForm({ ...form, [key]: value, is_boat_pilot_1: false, is_boat_pilot_2: false, is_boat_pilot_3: false })
    } else if (member4 === true && key === 'is_boat_pilot_4' && value === 'false') {
      setForm({ ...form, [key]: value, is_boat_pilot_1: true, is_boat_pilot_2: false, is_boat_pilot_3: false })
    } else {
      setForm({ ...form, [key]: value })
    }
  }
  
  const handleResend = async () => {
    setSubmit(true)
    setLoading(true)

    const isValidate = validateForm(form)
    if (isValidate === true) {
      const tempSendData = filterMask(form)
    
      const sendData = new FormData()

      if (newImage) {
        sendData.append("photo_1", newImage)
      }

      if (newImage2) {
        sendData.append("photo_2", newImage2)
      }

      if (member3 === true && newImage3) {
        sendData.append("photo_3", newImage2)
      }

      if (member3 === false) {
        delete tempSendData["photo_3"]
        delete tempSendData["is_captain_3"]
        delete tempSendData["name_3"]
        delete tempSendData["cpf_3"]
        delete tempSendData["phone_3"]
        delete tempSendData["is_boat_pilot_3"]
        delete tempSendData["birth_date_3"]
        delete tempSendData["fishing_license_3"]
        delete tempSendData["email_3"]
        delete tempSendData["shirt_size_3"]
        delete tempSendData["sex_3"]
      }

      if (member4 === true && newImage4) {
        sendData.append("photo_4", newImage2)
      }

      if (member4 === false) {
        delete tempSendData["photo_4"]
        delete tempSendData["is_captain_4"]
        delete tempSendData["name_4"]
        delete tempSendData["cpf_4"]
        delete tempSendData["phone_4"]
        delete tempSendData["is_boat_pilot_4"]
        delete tempSendData["birth_date_4"]
        delete tempSendData["fishing_license_4"]
        delete tempSendData["email_4"]
        delete tempSendData["shirt_size_4"]
        delete tempSendData["sex_4"]
      }

      for (const key in tempSendData) {
        sendData.append(key, tempSendData[key])
      }

      try {
        const response = await api.post('/subscription/', sendData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response) {
          setTempToken(response?.data?.token || "")
        }

        setLoading(false)
        handleCountDown()
      } catch(error) {
        setLoading(false)
        setErrorStatus({ status: "error", msg: error.message, open: true })
      }
    }
  }

  const handleCountDown = () => {
    setIsButtonEnabled(false)
    setTimeLeft(60)

    timeoutRef.current = setTimeout(() => {
      setIsButtonEnabled(true)
      setTimeLeft(0)
    }, 60000)

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current)
          return 0
        }

        return prevTime - 1
      })
    }, 1000)
  }

  const handleSubmit = async () => {
    setSubmit(true)
    
    const isValidate = validateForm(form)

    if (isValidate === true) {
      setLoading(true)
      const tempSendData = filterMask(form)
      const sendData = new FormData()

      if (newImage) {
        sendData.append("photo_1", newImage)
      }

      if (newImage2) {
        sendData.append("photo_2", newImage2)
      }

      if (member3 === true && newImage3) {
        sendData.append("photo_3", newImage2)
      }

      if (member3 === false) {
        delete tempSendData["photo_3"]
        delete tempSendData["is_captain_3"]
        delete tempSendData["name_3"]
        delete tempSendData["cpf_3"]
        delete tempSendData["phone_3"]
        delete tempSendData["birth_date_3"]
        delete tempSendData["is_boat_pilot_3"]
        delete tempSendData["fishing_license_3"]
        delete tempSendData["email_3"]
        delete tempSendData["shirt_size_3"]
        delete tempSendData["sex_3"]
      }

      if (member4 === true && newImage4) {
        sendData.append("photo_4", newImage2)
      }

      if (member4 === false) {
        delete tempSendData["photo_4"]
        delete tempSendData["is_captain_4"]
        delete tempSendData["name_4"]
        delete tempSendData["cpf_4"]
        delete tempSendData["phone_4"]
        delete tempSendData["birth_date_4"]
        delete tempSendData["is_boat_pilot_4"]
        delete tempSendData["fishing_license_4"]
        delete tempSendData["email_4"]
        delete tempSendData["shirt_size_4"]
        delete tempSendData["sex_4"]
      }

      for (const key in tempSendData) {
        sendData.append(key, tempSendData[key])
      }

      try {
        const response = await api.post('/team/', sendData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response) {
          setCode(response?.data?.subscription_number)
          setLoading(false)
          setControlModal(4)        
          setModalOpen(true)
        }

        // setLoading(false)
        // handleCountDown()
        // setControlModal(1)
        // setModalOpen(true)
      } catch(error) {
        setLoading(false)
        setErrorStatus({ status: "error", msg: error.message, open: true })
      }
    }
  }

  const handleSubmitCode = async () => {
    if (tempToken !== '' && tempToken !== null) {
      setLoading(true)
      
      const tempSendData = filterMask(form)
      const sendData = new FormData()

      if (newImage) {
        sendData.append("photo_1", newImage)
      }

      if (newImage2) {
        sendData.append("photo_2", newImage2)
      }

      if (member3 === true && newImage3) {
        sendData.append("photo_3", newImage2)
      }

      if (member3 === false) {
        delete tempSendData["photo_3"]
        delete tempSendData["is_captain_3"]
        delete tempSendData["name_3"]
        delete tempSendData["cpf_3"]
        delete tempSendData["phone_3"]
        delete tempSendData["birth_date_3"]
        delete tempSendData["fishing_license_3"]
        delete tempSendData["is_boat_pilot_3"]
        delete tempSendData["email_3"]
        delete tempSendData["shirt_size_3"]
        delete tempSendData["sex_3"]
      }

      if (member4 === true && newImage4) {
        sendData.append("photo_4", newImage2)
      }

      if (member4 === false) {
        delete tempSendData["photo_4"]
        delete tempSendData["is_captain_4"]
        delete tempSendData["name_4"]
        delete tempSendData["cpf_4"]
        delete tempSendData["phone_4"]
        delete tempSendData["birth_date_4"]
        delete tempSendData["fishing_license_4"]
        delete tempSendData["is_boat_pilot_4"]
        delete tempSendData["email_4"]
        delete tempSendData["shirt_size_4"]
        delete tempSendData["sex_4"]
      }

      for (const key in tempSendData) {
        sendData.append(key, tempSendData[key])
      }

      sendData.append("subscription_number", code)

      try {
        const response = await api.post('/subscription/', sendData, {
          headers: {
            'Authentication': tempToken,
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response) {
          setCode(response?.data?.subscription_number)
          setLoading(false)
          setControlModal(2)
        } else {
          setLoading(false)
          setErrorStatus({ status: "error", msg: "Ocorreu um erro ao realizar sua inscrição!", open: true })
        }
      } catch(error) {
        setLoading(false)
        setErrorStatus({ status: "error", msg: error.message, open: true })
      }
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <Box>
      <Box sx={styles.mainBox}>
        <Header title="Motorizada" />

        {/* Dados do Time */}
        <Box sx={styles.imputGroup}>
          <Typography
            variant="h5"
            align="center"
            width={"100%"}
            sx={{ fontWeight: "bold" }}
          >
            Dados da Equipe
          </Typography>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.name).err}
              helperText={submit && validator.Required(form.name).msg}
              value={form.name}
              onChange={(e) => onChange("name", e.target.value)}
              label="Nome da Equipe"
              fullWidth
            ></TextField>
          </Box>
          
          
          <TextField
            error={submit && validator.Required(form.zip_code).err}
            helperText={submit && validator.Required(form.zip_code).msg}
            value={mask.Cep(form.zip_code)}
            onChange={(e) => onChange("zip_code", e.target.value)}
            onBlur={(e) => {
              if (e.target.value.length === 10) {
                getCep(e.target.value)
              }
            }}
            label="Cep"
            sx={styles.halfField}
            inputProps={{ maxLength: 10 }}
          ></TextField>
        
          
          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.country).err}
              helperText={submit && validator.Required(form.country).msg}
              value={form.country}
              onChange={(e) => onChange("country", e.target.value)}
              label="País"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.state).err}
              helperText={submit && validator.Required(form.state).msg}
              value={form.state}
              onChange={(e) => onChange("state", e.target.value)}
              label="Estado"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.city).err}
              helperText={submit && validator.Required(form.city).msg}
              value={form.city}
              onChange={(e) => onChange("city", e.target.value)}
              label="Cidade"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.district).err}
              helperText={submit && validator.Required(form.district).msg}
              value={form.district}
              onChange={(e) => onChange("district", e.target.value)}
              label="Bairro"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.street).err}
              helperText={submit && validator.Required(form.street).msg}
              value={form.street}
              onChange={(e) => onChange("street", e.target.value)}
              label="Rua"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
          <TextField
              value={form.number}
              onChange={(e) => onChange("number", e.target.value)}
              label="Número"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.field}>
            <TextField
              value={form.complement}
              onChange={(e) => onChange("complement", e.target.value)}
              label="Complemento"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.vessel_name).err}
              helperText={submit && validator.Required(form.vessel_name).msg}
              value={form.vessel_name}
              onChange={(e) => onChange("vessel_name", e.target.value)}
              label="Nome da Embarcação"
              fullWidth
            ></TextField>
          </Box>
          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.vessel_number).err}
              helperText={submit && validator.Required(form.vessel_number).msg}
              value={form.vessel_number}
              onChange={(e) => onChange("vessel_number", e.target.value)}
              label="Nº da Embarcação"
              fullWidth
            ></TextField>
          </Box>
          <Box sx={styles.halfField}>
            <FormControl
              fullWidth
              error={submit && validator.Required(form.vessel_engine).err}
            >
              <InputLabel id="demo-simple-select-label">
                Motor da Embarcação
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.vessel_engine}
                label="Motor da Embarcação"
                onChange={(e) => onChange("vessel_engine", e.target.value)}
              >
                <MenuItem value={"De 15HP a 25HP"}>De 15HP a 25HP</MenuItem>
                <MenuItem value={"De 30HP a 60HP"}>De 30HP a 60HP</MenuItem>
                <MenuItem value={"De 60HP a 115HP"}>De 60HP a 115HP</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Required(form.naval_licence).err}
              helperText={
                submit && validator.Required(form.naval_licence).msg
              }
              value={form.naval_licence}
              onChange={(e) => onChange("naval_licence", e.target.value)}
              label="Licença Naval do Piloto"
              fullWidth
            ></TextField>
          </Box>
        </Box>

        {/* "Dados do integrante 1" */}
        <Box sx={styles.imputGroup}>
          <Typography
            variant="h5"
            align="center"
            width={"100%"}
            sx={{ fontWeight: "bold" }}
          >
            Dados do 1º Membro
          </Typography>

          <Box fullWidth sx={styles.imageBox}>
            <Box
              sx={{
                padding: 0,
                width: "100px",
                height: "100px",
              }}
            >
              <label
                htmlFor="img1"
                style={{
                  padding: 0,
                  width: "100%",
                  height: "100%",
                  margin: "5px 0",
                  display: "flex",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <img
                  style={styles.imageFormat}
                  alt="Img"
                  src={newImage ? URL.createObjectURL(newImage) : avatar}
                ></img>
              </label>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">Selecione uma Imagem!</Typography>
            </Box>
            <input
              style={{ display: "none" }}
              type="file"
              id="img1"
              onChange={(e) => {
                setNewImage(e.target.files[0])
              }}
            />
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.name_1).err}
              helperText={submit && validator.Required(form.name_1).msg}
              value={form.name_1}
              onChange={(e) => onChange("name_1", e.target.value)}
              label="Nome do Integrante"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Cpf(form.cpf_1).err}
              helperText={submit && validator.Cpf(form.cpf_1).msg}
              value={mask.Cpf(form.cpf_1)}
              onChange={(e) => onChange("cpf_1", e.target.value)}
              label="CPF"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                É o Capitão?
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={form.is_captain_1}
                name="radio-buttons-group"
                onChange={(e) => onChange("is_captain_1", e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />

                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Box sx={styles.halfField}>
              <DatePicker
                label="Nascimento"
                value={parseDate(form.birth_date_1)}
                onChange={(date) => {
                  if (date && isValid(date)) {
                    onChange("birth_date_1", formatDate(date));
                  }
                }}
                format="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={form.birth_date_1}
                    onChange={(e) => onChange("birth_date", e.target.value)}
                    error={submit && validator.Date16(form.birth_date_1).err}
                    helperText={submit && validator.Date16(form.birth_date_1).msg}               
                  />
                )}
                sx={{ width: "100%" }}
              />
            </Box>
          </LocalizationProvider>

          <Box sx={styles.halfField}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                É o Piloto da Equipe?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={form.is_boat_pilot_1}
                name="radio-buttons-group"
                onChange={(e) => onChange("is_boat_pilot_1", e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={styles.field}>
            <Typography variant="span" align="center" width={"100%"}>
              Idade mínima é 16 anos
            </Typography>
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Phone(form.phone_1).err}
              helperText={submit && validator.Phone(form.phone_1).msg}
              value={mask.Phone(form.phone_1)}
              onChange={(e) => onChange("phone_1", e.target.value)}
              label="Nº de Celular"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.fishing_license_1).err}
              helperText={submit && validator.Required(form.fishing_license_1).msg}
              value={mask.Text(form.fishing_license_1)}
              onChange={(e) => onChange("fishing_license_1", e.target.value)}
              label="Licença de Pesca"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.email_1).err}
              helperText={submit && validator.Required(form.email_1).msg}
              value={form.email_1}
              onChange={(e) => onChange("email_1", e.target.value)}
              label="Email"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <FormControl
              fullWidth
              error={submit && validator.Required(form.shirt_size_1).err}
            >
              <InputLabel id="demo-simple-select-label">
                Tamanho da Camiseta
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.shirt_size_1}
                label="Tamanho da Camiseta"
                onChange={(e) => onChange("shirt_size_1", e.target.value)}
              >
                <MenuItem value={""}>Escolha...</MenuItem>
                <MenuItem value={"P"}>P</MenuItem>
                <MenuItem value={"M"}>M</MenuItem>
                <MenuItem value={"G"}>G</MenuItem>
                <MenuItem value={"GG"}>GG</MenuItem>
                <MenuItem value={"XG"}>XG</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <FormControl sx={styles.halfField}>
            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.sex_1}
              label="Sexo"
              error={submit && form.sex_1 === ""}
              onChange={(e) => onChange("sex_1", e.target.value)}
            >
              <MenuItem value={"M"}>Masculino</MenuItem>
              <MenuItem value={"F"}>Feminino</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* "Dados do integrante 2" */}
        <Box sx={styles.imputGroup}>
          <Typography
            variant="h5"
            align="center"
            width={"100%"}
            sx={{ fontWeight: "bold" }}
          >
            Dados do 2º Membro
          </Typography>

          <Box fullWidth sx={styles.imageBox}>
            <Box
              sx={{
                padding: 0,
                width: "100px",
                height: "100px",
              }}
            >
              <label
                htmlFor="img2"
                style={{
                  padding: 0,
                  width: "100%",
                  height: "100%",
                  margin: "5px 0",
                  display: "flex",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <img
                  style={styles.imageFormat}
                  alt="Img"
                  src={newImage2 ? URL.createObjectURL(newImage2) : avatar}
                ></img>
              </label>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">Selecione uma Imagem!</Typography>
            </Box>
            <input
              style={{ display: "none" }}
              type="file"
              id="img2"
              onChange={(e) => {
                setNewImage2(e.target.files[0])
              }}
            />
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.name_2).err}
              helperText={submit && validator.Required(form.name_2).msg}
              value={form.name_2}
              onChange={(e) => onChange("name_2", e.target.value)}
              label="Nome do Integrante"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <TextField
              error={submit && validator.Cpf(form.cpf_2).err}
              helperText={submit && validator.Cpf(form.cpf_2).msg}
              value={mask.Cpf(form.cpf_2)}
              onChange={(e) => onChange("cpf_2", e.target.value)}
              label="CPF"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.halfField}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                É o Capitão?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={form.is_captain_2}
                name="radio-buttons-group"
                onChange={(e) => onChange("is_captain_2", e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <Box sx={styles.halfField}>
              <DatePicker
                label="Nascimento"
                value={parseDate(form.birth_date_2)}
                onChange={(date) => {
                  if (date && isValid(date)) {
                    onChange("birth_date_2", formatDate(date));
                  }
                }}
                format="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={form.birth_date_2}
                    onChange={(e) => onChange("birth_date_2", e.target.value)}
                    error={submit && validator.Date16(form.birth_date_2).err}
                    helperText={submit && validator.Date16(form.birth_date_2).msg}               
                  />
                )}
                sx={{ width: "100%" }}
              />
            </Box>
          </LocalizationProvider>

          <Box sx={styles.halfField}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                É o Piloto da Equipe?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={form.is_boat_pilot_2}
                name="radio-buttons-group"
                onChange={(e) => onChange("is_boat_pilot_2", e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={styles.field}>
            <Typography variant="span" align="center" width={"100%"}>
              Idade mínima é 16 anos
            </Typography>
          </Box>
          
          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Phone(form.phone_2).err}
              helperText={submit && validator.Phone(form.phone_2).msg}
              value={mask.Phone(form.phone_2)}
              onChange={(e) => onChange("phone_2", e.target.value)}
              label="Nº de Celular"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.fishing_license_2).err}
              helperText={submit && validator.Required(form.fishing_license_2).msg}
              value={mask.Text(form.fishing_license_2)}
              onChange={(e) => onChange("fishing_license_2", e.target.value)}
              label="Licença de Pesca"
              fullWidth
            ></TextField>
          </Box>

          <Box sx={styles.field}>
            <TextField
              error={submit && validator.Required(form.email_2).err}
              helperText={submit && validator.Required(form.email_2).msg}
              value={form.email_2}
              onChange={(e) => onChange("email_2", e.target.value)}
              label="Email"
              fullWidth
            ></TextField>
          </Box>
          <Box sx={styles.halfField}>
            <FormControl
              fullWidth
              error={submit && validator.Required(form.shirt_size_2).err}
            >
              <InputLabel id="demo-simple-select-label">
                Tamanho da Camiseta
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.shirt_size_2}
                label="Tamanho da Camiseta"
                onChange={(e) => onChange("shirt_size_2", e.target.value)}
              >
                <MenuItem value={""}>Escolha...</MenuItem>
                <MenuItem value={"P"}>P</MenuItem>
                <MenuItem value={"M"}>M</MenuItem>
                <MenuItem value={"G"}>G</MenuItem>
                <MenuItem value={"GG"}>GG</MenuItem>
                <MenuItem value={"XG"}>XG</MenuItem>
              </Select>
            </FormControl>
          </Box>

          
          <FormControl sx={styles.halfField}>
            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.sex_2}
              label="Sexo"
              error={submit && form.sex_2 === ""}
              onChange={(e) => onChange("sex_2", e.target.value)}
            >
              <MenuItem value={""}>Escolha...</MenuItem>
              <MenuItem value={"M"}>Masculino</MenuItem>
              <MenuItem value={"F"}>Feminino</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* "Dados do integrante 3" */}
        {member3 === true && 
          <Box sx={styles.imputGroup}>
            <Typography
              variant="h5"
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold" }}
            >
              Dados do 3º Membro
            </Typography>

            <Box fullWidth sx={styles.imageBox}>
              <Box
                sx={{
                  padding: 0,
                  width: "100px",
                  height: "100px",
                }}
              >
                <label
                  htmlFor="img3"
                  style={{
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    margin: "5px 0",
                    display: "flex",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={styles.imageFormat}
                    alt="Img"
                    src={newImage3 ? URL.createObjectURL(newImage3) : avatar}
                  ></img>
                </label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">Selecione uma Imagem!</Typography>
              </Box>
              <input
                style={{ display: "none" }}
                type="file"
                id="img3"
                onChange={(e) => {
                  setNewImage3(e.target.files[0])
                }}
              />
            </Box>

            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(form.name_3).err}
                helperText={submit && validator.Required(form.name_3).msg}
                value={form.name_3}
                onChange={(e) => onChange("name_3", e.target.value)}
                label="Nome do Integrante"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.halfField}>
              <TextField
                error={submit && validator.Cpf(form.cpf_3).err}
                helperText={submit && validator.Cpf(form.cpf_3).msg}
                value={mask.Cpf(form.cpf_3)}
                onChange={(e) => onChange("cpf_3", e.target.value)}
                label="CPF"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.halfField}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  É o Capitão?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={form.is_captain_3}
                  name="radio-buttons-group"
                  onChange={(e) => onChange("is_captain_3", e.target.value)}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
              <Box sx={styles.halfField}>
                <DatePicker
                  label="Nascimento"
                  value={parseDate(form.birth_date_3)}
                  onChange={(date) => {
                    if (date && isValid(date)) {
                      onChange("birth_date_3", formatDate(date));
                    }
                  }}
                  format="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={form.birth_date_3}
                      onChange={(e) => onChange("birth_date_3", e.target.value)}
                      error={submit && validator.Date16(form.birth_date_3).err}
                      helperText={submit && validator.Date16(form.birth_date_3).msg}               
                    />
                  )}
                  sx={{ width: "100%" }}
                />
              </Box>
            </LocalizationProvider>

            <Box sx={styles.halfField}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                É o Piloto da Equipe?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={form.is_boat_pilot_3}
                name="radio-buttons-group"
                onChange={(e) => onChange("is_boat_pilot_3", e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={styles.field}>
            <Typography variant="span" align="center" width={"100%"}>
              Idade mínima é 16 anos
            </Typography>
          </Box>
            
            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Phone(form.phone_3).err}
                helperText={submit && validator.Phone(form.phone_3).msg}
                value={mask.Phone(form.phone_3)}
                onChange={(e) => onChange("phone_3", e.target.value)}
                label="Nº de Celular"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(form.fishing_license_3).err}
                helperText={submit && validator.Required(form.fishing_license_3).msg}
                value={mask.Text(form.fishing_license_3)}
                onChange={(e) => onChange("fishing_license_3", e.target.value)}
                label="Licença de Pesca"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(form.email_3).err}
                helperText={submit && validator.Required(form.email_3).msg}
                value={form.email_3}
                onChange={(e) => onChange("email_3", e.target.value)}
                label="Email"
                fullWidth
              ></TextField>
            </Box>
            <Box sx={styles.halfField}>
              <FormControl
                fullWidth
                error={submit && validator.Required(form.shirt_size_3).err}
              >
                <InputLabel id="demo-simple-select-label">
                  Tamanho da Camiseta
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.shirt_size_3}
                  label="Tamanho da Camiseta"
                  onChange={(e) => onChange("shirt_size_3", e.target.value)}
                >
                  <MenuItem value={""}>Escolha...</MenuItem>
                  <MenuItem value={"P"}>P</MenuItem>
                  <MenuItem value={"M"}>M</MenuItem>
                  <MenuItem value={"G"}>G</MenuItem>
                  <MenuItem value={"GG"}>GG</MenuItem>
                  <MenuItem value={"XG"}>XG</MenuItem>
                </Select>
              </FormControl>
            </Box>
       
            <FormControl sx={styles.halfField}>
              <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.sex_3}
                label="Sexo"
                error={submit && form.sex_3 === ""}
                onChange={(e) => onChange("sex_3", e.target.value)}
              >
                <MenuItem value={""}>Escolha...</MenuItem>
                <MenuItem value={"M"}>Masculino</MenuItem>
                <MenuItem value={"F"}>Feminino</MenuItem>
              </Select>
            </FormControl>
          </Box>
        }

        {/* "Dados do integrante 4" */}
        {member4 === true &&
          <Box sx={styles.imputGroup}>
            <Typography
              variant="h5"
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold" }}
            >
              Dados do 4º Membro
            </Typography>

            <Box fullWidth sx={styles.imageBox}>
              <Box
                sx={{
                  padding: 0,
                  width: "100px",
                  height: "100px",
                }}
              >
                <label
                  htmlFor="img4"
                  style={{
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    margin: "5px 0",
                    display: "flex",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={styles.imageFormat}
                    alt="Img"
                    src={newImage4 ? URL.createObjectURL(newImage4) : avatar}
                  ></img>
                </label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">Selecione uma Imagem!</Typography>
              </Box>
              <input
                style={{ display: "none" }}
                type="file"
                id="img4"
                onChange={(e) => {
                  setNewImage4(e.target.files[0])
                }}
              />
            </Box>

            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(form.name_4).err}
                helperText={submit && validator.Required(form.name_4).msg}
                value={form.name_4}
                onChange={(e) => onChange("name_4", e.target.value)}
                label="Nome do Integrante"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.halfField}>
              <TextField
                error={submit && validator.Cpf(form.cpf_4).err}
                helperText={submit && validator.Cpf(form.cpf_4).msg}
                value={mask.Cpf(form.cpf_4)}
                onChange={(e) => onChange("cpf_4", e.target.value)}
                label="CPF"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.halfField}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  É o Capitão?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={form.is_captain_4}
                  name="radio-buttons-group"
                  onChange={(e) => onChange("is_captain_4", e.target.value)}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Não"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Sim"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
              <Box sx={styles.halfField}>
                <DatePicker
                  label="Nascimento"
                  value={parseDate(form.birth_date_4)}
                  onChange={(date) => {
                    if (date && isValid(date)) {
                      onChange("birth_date_4", formatDate(date));
                    }
                  }}
                  format="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={form.birth_date_4}
                      onChange={(e) => onChange("birth_date_4", e.target.value)}
                      error={submit && validator.Date16(form.birth_date_4).err}
                      helperText={submit && validator.Date16(form.birth_date_4).msg}               
                    />
                  )}
                  sx={{ width: "100%" }}
                />
              </Box>
            </LocalizationProvider>

            <Box sx={styles.halfField}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                É o Piloto da Equipe?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={form.is_boat_pilot_4}
                name="radio-buttons-group"
                onChange={(e) => onChange("is_boat_pilot_4", e.target.value)}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Não"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sim"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={styles.field}>
            <Typography variant="span" align="center" width={"100%"}>
              Idade mínima é 16 anos
            </Typography>
          </Box>
            
            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Phone(form.phone_4).err}
                helperText={submit && validator.Phone(form.phone_4).msg}
                value={mask.Phone(form.phone_4)}
                onChange={(e) => onChange("phone_4", e.target.value)}
                label="Nº de Celular"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(form.fishing_license_4).err}
                helperText={submit && validator.Required(form.fishing_license_4).msg}
                value={mask.Text(form.fishing_license_4)}
                onChange={(e) => onChange("fishing_license_4", e.target.value)}
                label="Licença de Pesca"
                fullWidth
              ></TextField>
            </Box>

            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(form.email_4).err}
                helperText={submit && validator.Required(form.email_4).msg}
                value={form.email_4}
                onChange={(e) => onChange("email_4", e.target.value)}
                label="Email"
                fullWidth
              ></TextField>
            </Box>
            <Box sx={styles.halfField}>
              <FormControl
                fullWidth
                error={submit && validator.Required(form.shirt_size_4).err}
              >
                <InputLabel id="demo-simple-select-label">
                  Tamanho da Camiseta
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.shirt_size_4}
                  label="Tamanho da Camiseta"
                  onChange={(e) => onChange("shirt_size_4", e.target.value)}
                >
                  <MenuItem value={""}>Escolha...</MenuItem>
                  <MenuItem value={"P"}>P</MenuItem>
                  <MenuItem value={"M"}>M</MenuItem>
                  <MenuItem value={"G"}>G</MenuItem>
                  <MenuItem value={"GG"}>GG</MenuItem>
                  <MenuItem value={"XG"}>XG</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <FormControl sx={styles.halfField}>
              <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.sex_4}
                label="Sexo"
                error={submit && form.sex_4 === ""}
                onChange={(e) => onChange("sex_4", e.target.value)}
              >
                <MenuItem value={""}>Escolha...</MenuItem>
                <MenuItem value={"M"}>Masculino</MenuItem>
                <MenuItem value={"F"}>Feminino</MenuItem>
              </Select>
            </FormControl>
          </Box>
        }

        <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 5px 10px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          startIcon={<Delete />}
          disabled={filterDisabledButton('delete')}
          onClick={() => deleteMember()}
        >
          Membro
        </Button>
        <Button
          variant="contained"
          startIcon={<Add />}
          disabled={filterDisabledButton('add')}
          onClick={() => addMember()}
        >
          Membro
        </Button>
      </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "flex-start",
          }}
        >
          <Button variant="contained" color="error" onClick={() => window.history.back()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
          >
            Concluir
          </Button>
        </Box>

        <Footer />
      </Box>

      <Backdrop
        open={loading}
        sx={{
          color: "#333333",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          pointerEvents: "none",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open={errorStatus.open}
        onClose={handleCLoseAlert}
      >
        <Box sx={styles.ErrorModal}>
          <Typography
            variant="h5"
            align="center"
            width={"100%"}
            sx={{ fontWeight: "bold" }}
          >
            Erro
          </Typography>
          
          <CancelIcon 
            sx={{...styles.imageBox,  fontSize: 100, margin: "20px 0px"}} 
            color="error" 
          />

          <Typography component="p" align="center" width={"100%"}>
            {errorStatus.msg}
          </Typography>

          <Button
            sx={{ display: "flex", alignSelf: "center", marginTop: "20px" }}
            variant="contained" 
            color="primary" 
            onClick={handleCLoseAlert}
          >
            Confirmar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box sx={styles.alertBox}>
        {controlModal === 1 ? (
          <Box sx={styles.imputGroup}>
            <Box sx={styles.imageBox}>
              <img src={warning} alt="Atenção" style={{ width: "140px" }} />
            </Box>
            <Typography
              variant="h5"
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold" }}
            >
              AINDA NÃO ACABOU
            </Typography>
            <Typography
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold", fontSize: "1rem" }}
            >
              CÓDIGO DE CONFIRMAÇÃO
            </Typography>
            <Typography component="p" align="center" width={"100%"}>
              Insira abaixo o código enviado para o e-mail <b>{form.responsible_email}</b> e para o
              Whatsapp <b>{form.responsible_phone}</b> para confirmar sua solicitação!
            </Typography>
            <Box sx={styles.field}>
              <TextField
                error={submit && validator.Required(code).err}
                helperText={submit && validator.Required(code).msg}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                label="Código"
                fullWidth
              ></TextField>
            </Box>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ margin: "10px" }}
                onClick={() => {
                  setCode("")
                  setTempToken("")
                  setNewImage(null)
                  setNewImage2(null)
                  setSubmit(false)
                  setModalOpen(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ margin: "10px" }}
                onClick={handleSubmitCode}
              >
                Concluir
              </Button>
            </Box>
            <Typography component="p" align="center" width={"100%"}>
              Não recebeu o código?
              <Button
                variant="text"
                disabled={!isButtonEnabled}
                onClick={() => {
                  handleResend()
                }}
              >
                {isButtonEnabled ? "Reenviar" : `Reenviar (${timeLeft}s)`}
              </Button>
            </Typography>
          </Box>
        ) : controlModal === 2 ? (
          <Box sx={styles.imputGroup}>
            <Box sx={styles.imageBox}>
              <img src={warning} alt="Atenção" style={{ width: "140px" }} />
            </Box>
              <Typography
                variant="h5"
                align="center"
                width={"100%"}
                sx={{ fontWeight: "bold" }}
              >
                SOLICITAÇÃO CONCLUÍDA!
              </Typography>

              <Typography
                component="p"
                textAlign="center"
                width={"100%"}
                sx={{ marginTop: "20px" }}
              >
                Sua <b>solicitação</b> de inscrição na modalidade{" "}
                <b>PESCA MOTORIZADA</b> do 41º Fipe foi realizada!
              </Typography>

              <Typography
                component="h5"
                variant="h5"
                textAlign="center"
                fontWeight="bold"
                width={"100%"}
                sx={{ marginTop: "20px" }}
              >
                Efetue o pagamento para confirmar sua inscrição!
              </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setControlModal(3);
                }}
              >
                Continuar
              </Button>
            </Box>
          </Box>
        ) : controlModal === 3 ? (
          <Box sx={styles.imputGroup}>
            <Box sx={styles.imageBox}>
              <img
                src="https://devtools.com.br/img/pix/logo-pix-png-icone-520x520.png"
                alt="Confirm"
                style={{ width: "200px" }}
              />
            </Box>
            <Typography
              variant="h5"
              align="center"
              width={"100%"}
              sx={{ fontWeight: "bold" }}
            >
              PAGAMENTO
            </Typography>
            <Typography
              component="p"
              textAlign="center"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              <b>Realize o pagamento</b> do valor de <b>R${MODALITY_VALUE}</b>{" "}
              para a chave <b>PIX</b> abaixo!
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              textAlign="center"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              {PIX_TYPE}: <b id="key">{PIX_KEY}</b>{" "}
              <IconButton
                sx={{ margin: "0", padding: "0" }}
                aria-label="copiar"
                color="success"
                onClick={() => {
                  setErrorStatus({
                    status: "success",
                    msg: "Chave PIX Copiada!",
                    open: true,
                  });
                  const content = document.getElementById("key").innerText;
                  navigator.clipboard.writeText(content);
                }}
              >
                <ContentCopy />
              </IconButton>
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              textAlign="center"
              fontWeight="bold"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              Seu código de inscrição é <b>{code}</b>
            </Typography>
            <Typography
              component="p"
              textAlign="center"
              width={"100%"}
              sx={{ marginTop: "20px" }}
            >
              Encaminhe o <b>comprovante do pagamento</b> e o código <b>{code}</b>{" "}
              para o número <b id="tel">{TEL}</b>{" "}
              <IconButton
                sx={{ margin: "0", padding: "0" }}
                aria-label="copiar"
                color="success"
                onClick={() => {
                  setErrorStatus({
                    status: "success",
                    msg: "Número Copiado!",
                    open: true,
                  });
                  const content = document.getElementById("tel").innerText;
                  navigator.clipboard.writeText(content);
                }}
              >
                <ContentCopy />
              </IconButton>{" "}
              ou clique no botão abaixo e vá direto para nosso canal de
              comunicação.
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px"}}
            >
              <Button  variant="contained" color="error"   sx={{margin: "0 10px"}} onClick={()=>{
                setCode("")
                setTempToken("")
                setForm(formFields)
                setSubmit(false)
                setNewImage(null)
                setNewImage2(null)
                setControlModal(0)
                setModalOpen(false)
              }}>
                  FECHAR
                </Button>
              <Link
                href={`https://api.whatsapp.com/send?phone=${TEL.replace(/[^0-9]/g, "")}&text=Ol%C3%A1!%20%0A%F0%9F%92%B2Segue%20o%20comprovante%20de%20pagamento%20da%20inscri%C3%A7%C3%A3o.%0A%0AEquipe:%20${form.name}%20%0AC%C3%B3digo:%20${code}`}
                underline="none"
                sx={{margin: "0 10px"}}
              >
                <Button variant="contained" color="success">
                  enviar
                </Button>
              </Link>
            </Box>
          </Box>
        ) : controlModal === 4 ? (
          <Box sx={styles.imputBox}>
            <Box>
              <CheckCircleIcon 
                sx={{...styles.imageBox,  fontSize: 150}} 
                color="success" 
              />
            </Box>
            <Box sx={{ margin: "20px 0px" }}>
              <Typography sx={{textAlign: "center", marginBottom: "20px"}} variant="h6" fontWeight="bold">
                INSCRIÇÃO CONFIRMADA
              </Typography>

              <Typography variant="subtitle1" fontWeight="bold">
                Parabéns, o participante {form.name} foi cadastrado com sucesso!
              </Typography>

              <Typography sx={{textAlign: "center", margin: "10px"}}>
                Acesse o comprovante abaixo!
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setControlModal(5)
              }}
            >
              Comprovante
            </Button>
          </Box>
        ) : controlModal === 5 ? (
          <Box style={styles.imputBox}>
            <Box sx={{ width: "100%" }} id="print">
              <Box sx={styles.session}>
                <Typography variant="h6" fontWeight="bold">
                  COMPROVANTE DE INSCRIÇÃO
                </Typography>

                <div style={{ display: "flex", alignItems: "center", height: 100, width: 100 }}>
                  <img style={{ height: "100%", width: "100%" }} src={peixe} alt="perfil" />
                </div>

                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {form.name}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography variant="h5">Categoria: </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      Motorizada
                    </Typography>
                  </Box>
                </Box>              
              </Box>

              <Box sx={styles.session}>
                <Box sx={styles.texts}>
                  <Box sx={styles.format}>
                    <Typography variant="h6">CPF: </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {mask.Cpf(form.cpf_1)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{ ...styles.session, borderBottom: "none" }}>
                <Typography variant="h6" fontWeight="bold">
                  Código de inscrição
                </Typography>

                <Typography variant="h3" fontWeight="bold">
                  {code}
                </Typography>

                <Box maxWidth={"160px"}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={code}
                    viewBox={`0 0 256 256`}
                  />
                </Box>

                <Typography variant="subtitle1">
                  TIRE UM PRINT DESTA TELA*
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ marginTop: "10px" }}
                  onClick={() => window.location.reload()}
                >
                  Voltar ao Inicio
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
        </Box>
      </Modal>
    </Box>
  )
}

export default Motorizada
