export const footerMain = {
  width: "100%",
  minHeight: "300px",
  background: "#235450",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "40px",
  flexWrap: "wrap",
  height: "100%",
}

export const gif = {
  maxWidth: '400px'
}

export const sessoes = {
  minWidth: '200px',
  minHeight: '50px'
}

export const contatos = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: { md: 'flex-start', xs: 'center' },
  maxWidth: '270px'
}

export const riscBox = {
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}
